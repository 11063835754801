import React, {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import BrandLogo from '../../assets/placemakr-logo-pool.png'
import {getLogger} from '../../services/logging'
import {Metric, pushMetric} from '../../services/metrics'
import {getPropertyConfig, getPropertyId} from '../../services/propertyContext'
import {useAppDispatch} from '../../store/hooks'
import {setAuthenticated} from '../../store/authSlice'
import {removeGuestToken} from '../../services/guestToken'
import {
  faCircleRight,
  faQuestionCircle
} from '@fortawesome/free-regular-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import ReactGA from 'react-ga4'
import {getConfig} from '../../services/config'

interface WindowWithGTag extends Window {
  gtag: Function
}

declare const window: WindowWithGTag

const logger = getLogger('WelcomePage')
const {googleAnalyticsId} = getConfig()

/**
 * Resets the Google Analytics user ID.
 *
 * This function generates a random UUID and sets it as the user ID for Google Analytics.
 * This is useful for resetting the user ID when a user logs out.
 */
function resetGoogleAnalyticsUser(): void {
  // configure gtag for google analytics
  if (window.gtag) {
    // generate random uuid for user id
    const userId = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      c => {
        const r = (Math.random() * 16) | 0
        const v = c === 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
      }
    )

    window.gtag('config', googleAnalyticsId, {'user_id': userId})
    window.gtag('event', 'new_session', {'event_label': 'New Session Started'})
    logger.info({
      message: `Setting Google Analytics user ID to ${userId}`,
      data: {userId}
    })
  } else {
    logger.warn({message: 'window.gtag is not defined'})
  }
}

const WelcomePage = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const propertyId = getPropertyId()
  const {helpPageButtonEnabled} = getPropertyConfig()

  useEffect(() => {
    logger.debug({message: 'Clearing checkin session...'})
    dispatch(setAuthenticated(false))
    removeGuestToken()
    resetGoogleAnalyticsUser()
  }, [])

  return (
    <section
      className={
        'min-h-screen flex flex-col justify-center items-center ' +
        'bg-primary text-white text-center'
      }
    >
      <img src={BrandLogo} className="w-16 mb-8" />
      <h1 className="tracking-wide" style={{fontSize: '60px'}}>
        Welcome to <span className="font-bold">Placemakr.</span>
      </h1>
      <h2 className="mb-8" style={{fontSize: '40px'}}>
        Let's get you checked in.
      </h2>
      <div className="flex flex-row justify-center items-center gap-4">
        <button
          className="btn btn-secondary btn-lg btn-wide font-black text-2xl"
          onClick={() => {
            logger.debug({message: 'Starting guest checkin process...'})
            pushMetric(Metric.CheckinStarted, 1, {Property: propertyId})
            ReactGA.event({
              category: 'Checkin_Kiosk',
              action: 'Checkin_Start',
              value: 1
            })
            navigate('/checkin')
          }}
        >
          <FontAwesomeIcon icon={faCircleRight} />
          <span>check in</span>
        </button>
        {helpPageButtonEnabled ? (
          <button
            className="btn btn-secondary btn-lg btn-wide font-black text-2xl"
            onClick={() => {
              logger.debug({message: 'Getting help...'})
              ReactGA.event({
                category: 'Checkin_Kiosk',
                action: 'Help_Open',
                value: 1
              })
              navigate('/help')
            }}
          >
            <FontAwesomeIcon icon={faQuestionCircle} />
            <span>get help</span>
          </button>
        ) : (
          ''
        )}
      </div>
    </section>
  )
}

export default WelcomePage
