import React, {useEffect, useState} from 'react'
import {
  useGetReservationCheckinQuery,
  useSendCheckinNotificationMutation
} from '../../services/guestCheckinApi'
import {selectIsAuthenticated} from '../../store/authSlice'
import {useAppDispatch, useAppSelector} from '../../store/hooks'
import VerifyIdentity from '../identity/VerifyIdentity'
import ViewRoomDetails from '../access/ViewAccessInfo'
import CompleteCheckin from '../directions/ViewCheckinDirections'
import LoadingSpinner, {LoadingSpinnerSize} from '../common/LoadingSpinner'
import CheckinSidebar from '../common/CheckinSidebar'
import FindReservation from '../reservation/FindReservation'
import HelpModal from '../help/HelpModal'
import {useNavigate} from 'react-router-dom'
import {useIdleTimer} from 'react-idle-timer'
import {
  CheckinAction,
  CheckinErrorNotification,
  CheckinStep,
  NotificationType
} from '../../types'
import {selectIsHelpOpen, setIsHelpOpen} from '../../store/helpSlice'
import {getConfig} from '../../services/config'
import {getPropertyConfig, getPropertyId} from '../../services/propertyContext'
import {getLogger} from '../../services/logging'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircleQuestion} from '@fortawesome/free-regular-svg-icons'
import ReactGA from 'react-ga4'
import clsx from 'clsx'
import {Metric, pushMetric} from '../../services/metrics'
import VerifyPayment from '../payment/VerifyPayment'
const {checkinRulesEnabled, idleTimeout} = getConfig()
const logger = getLogger('CheckinPage')

interface CheckinPageState {
  currentStep: CheckinStep
  completedSteps: CheckinStep[]
}

function addCompletedStep(
  completedSteps: CheckinStep[],
  step: CheckinStep
): CheckinStep[] {
  if (!completedSteps.includes(step)) {
    completedSteps.push(step)
  }
  return completedSteps
}

const CheckinPage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const isHelpOpen = useAppSelector(selectIsHelpOpen)
  const kioskPropertyId = getPropertyId()
  const {helpModalButtonEnabled} = getPropertyConfig()
  const [sendCheckNotification] = useSendCheckinNotificationMutation()

  const [checkinPageState, setCheckinPageState] = useState<CheckinPageState>({
    currentStep: null,
    completedSteps: []
  })

  const reservationCheckinQuery = useGetReservationCheckinQuery(
    kioskPropertyId,
    {
      skip: !isAuthenticated
    }
  )

  const isDataLoading =
    reservationCheckinQuery.isLoading || !reservationCheckinQuery.data

  const reservationCheckin = reservationCheckinQuery.data

  // idle timeout - closes checkin session when there's no activity
  useIdleTimer({
    onIdle: () => {
      logger.info({message: 'Idle timeout'})
      navigate('/')
    },
    timeout: idleTimeout,
    throttle: 500
  })

  useEffect(() => {
    if (isAuthenticated && reservationCheckin) {
      const isCheckinAllowed = checkinRulesEnabled
        ? reservationCheckin?.checkinAccess?.action === CheckinAction.Allow
        : true

      logger.info({
        message: 'User confirmed reservation',
        data: {checkinRulesEnabled, isCheckinAllowed}
      })

      if (isCheckinAllowed) {
        logger.info({
          message: 'User is allowed to checkin',
          data: {reservationCheckin}
        })

        // send google analytics event
        ReactGA.event({
          category: 'Checkin_Kiosk',
          action: 'Reservation_Confirm',
          value: 1
        })

        // push reservation confirmed metric
        pushMetric(Metric.ReservationConfirmed, 1, {
          Property: getPropertyId()
        })

        // send checkin started notification
        sendCheckNotification({
          type: NotificationType.CheckinStarted
        })
      } else {
        // navigate to error page
        const code = reservationCheckin?.checkinAccess?.code

        logger.warn({
          message: 'User checkin not allowed',
          data: {code, reservationCheckin}
        })

        // send checkin not allowed notification
        sendCheckNotification({
          type: NotificationType.CheckinNotAllowed,
          error: code
        } as CheckinErrorNotification)

        navigate(`/error?code=${code}`)
      }
    }

    // init checkin state
    const checkinState = isAuthenticated
      ? {
          currentStep: CheckinStep.VerifyIdentity,
          completedSteps: [CheckinStep.FindReservation]
        }
      : {
          currentStep: CheckinStep.FindReservation,
          completedSteps: []
        }
    setCheckinPageState(checkinState)
  }, [isAuthenticated, reservationCheckin])

  const {currentStep} = checkinPageState

  logger.info({
    message: 'Checkin page state changed',
    data: {
      isAuthenticated,
      checkinPageState
    }
  })

  return (
    <>
      <div className="max-w-screen-xl mx-auto min-h-screen flex flex-row items-stretch">
        <div className="flex-none bg-primary w-44 min-w-44">
          <CheckinSidebar
            currentStep={checkinPageState.currentStep}
            completedSteps={checkinPageState.completedSteps}
          />
        </div>
        <div className="flex-1 bg-white relative overflow-auto">
          <button
            className={clsx(
              'absolute top-12 right-0 btn bg-yellow-100 w-48 rounded-none rounded-l-lg',
              {
                'hidden': !helpModalButtonEnabled
              }
            )}
            onClick={() => {
              ReactGA.event({
                category: 'Checkin_Kiosk',
                action: 'Help_Open',
                value: 1
              })

              dispatch(setIsHelpOpen(true))
            }}
          >
            <FontAwesomeIcon icon={faCircleQuestion} />
            <span>get help</span>
          </button>

          {/* Not authenticated */}
          {!isAuthenticated ? <FindReservation /> : null}

          {/* Loading/Checkin Steps */}
          {isAuthenticated && isDataLoading ? (
            <div className="h-full w-full flex flex-row justify-center items-center gap-2">
              <LoadingSpinner size={LoadingSpinnerSize.Large} />
              <div>Loading Check-in...</div>
            </div>
          ) : (
            ''
          )}
          {isAuthenticated && !isDataLoading ? (
            <>
              {currentStep === CheckinStep.VerifyIdentity ? (
                <VerifyIdentity
                  reservationCheckin={reservationCheckinQuery.data}
                  onNext={() => {
                    setCheckinPageState({
                      ...checkinPageState,
                      currentStep: CheckinStep.ViewRoomDetails,
                      completedSteps: addCompletedStep(
                        checkinPageState.completedSteps,
                        CheckinStep.VerifyIdentity
                      )
                    })
                  }}
                />
              ) : (
                ''
              )}

              {/* {currentStep === CheckinStep.VerifyPayment ? (
                <VerifyPayment
                  reservationCheckin={reservationCheckinQuery.data}
                  onNext={() => {
                    setCheckinPageState({
                      ...checkinPageState,
                      currentStep: CheckinStep.ViewRoomDetails,
                      completedSteps: addCompletedStep(
                        checkinPageState.completedSteps,
                        CheckinStep.VerifyPayment
                      )
                    })
                  }}
                />
              ) : (
                ''
              )} */}

              {currentStep === CheckinStep.ViewRoomDetails ? (
                <ViewRoomDetails
                  onNext={() => {
                    setCheckinPageState({
                      ...checkinPageState,
                      currentStep: CheckinStep.CompleteCheckin,
                      completedSteps: addCompletedStep(
                        checkinPageState.completedSteps,
                        CheckinStep.ViewRoomDetails
                      )
                    })
                  }}
                />
              ) : (
                ''
              )}

              {currentStep === CheckinStep.CompleteCheckin ? (
                <CompleteCheckin
                  reservationCheckin={reservationCheckinQuery.data}
                />
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}
        </div>
      </div>
      <HelpModal
        isOpen={isHelpOpen}
        onClose={() => {
          dispatch(setIsHelpOpen(false))
        }}
      />
    </>
  )
}

export default CheckinPage
