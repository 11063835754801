import React from 'react'
import BrandLogo from '../../assets/placemakr-logo-pool.png'
import clsx from 'clsx'
import {CheckinStep} from '../../types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faArrowRightFromBracket
} from '@fortawesome/free-solid-svg-icons'
import {useNavigate} from 'react-router-dom'
import ReactGA from 'react-ga4'

interface CheckinSidebarProps {
  currentStep: CheckinStep
  completedSteps: CheckinStep[]
}

interface StepProps {
  name: string
  label: string
  isActive: boolean
  isCompleted: boolean
}

const Step = ({name, label, isActive, isCompleted}: StepProps) => {
  return (
    <div className="flex flex-col items-center gap-1">
      <div
        className={clsx(
          'flex flex-col items-center justify-center w-10 h-10 rounded-full',
          {
            'bg-cabana': isCompleted || isActive,
            'border border-2 border-gray-400': !isCompleted && !isActive
          }
        )}
      >
        <span
          className={clsx('text-primary font-medium text-lg', {
            'hidden': isCompleted,
            'text-white': !isCompleted
          })}
        >
          {name}
        </span>

        <FontAwesomeIcon
          icon={faCheck}
          className={clsx('text-xl text-primary', {'hidden': !isCompleted})}
        />
      </div>
      <div className="text-sm text-center font-medium">{label}</div>
    </div>
  )
}

const StepSeparator = () => {
  return <div className="h-12 border border-secondary"></div>
}

const CheckinSidebar = ({currentStep, completedSteps}: CheckinSidebarProps) => {
  const navigate = useNavigate()
  return (
    <div className="flex flex-col items-center justify-start text-white px-8 py-12">
      <img src={BrandLogo} className="w-8 mb-6" />

      <div className="mt-4 flex flex-col items-center gap-2">
        <Step
          name="1"
          label="Find Reservation"
          isActive={currentStep === CheckinStep.FindReservation}
          isCompleted={completedSteps.includes(CheckinStep.FindReservation)}
        />
        <StepSeparator />
        <Step
          name="2"
          label="Complete Verification"
          isActive={currentStep === CheckinStep.VerifyIdentity}
          isCompleted={completedSteps.includes(CheckinStep.VerifyIdentity)}
        />
        <StepSeparator />
        {/* <Step
          name="3"
          label="Add Credit Card"
          isActive={currentStep === CheckinStep.VerifyPayment}
          isCompleted={completedSteps.includes(CheckinStep.VerifyPayment)}
        />
        <StepSeparator /> */}
        <Step
          name="3"
          label="Receive Room Details"
          isActive={currentStep === CheckinStep.ViewRoomDetails}
          isCompleted={completedSteps.includes(CheckinStep.ViewRoomDetails)}
        />
        <StepSeparator />
        <Step
          name="4"
          label="Go Have A Great Stay!"
          isActive={currentStep === CheckinStep.CompleteCheckin}
          isCompleted={completedSteps.includes(CheckinStep.CompleteCheckin)}
        />
      </div>

      <div
        className="mt-10 w-full flex flex-row items-center justify-center gap-2 cursor-pointer"
        onClick={() => {
          ReactGA.event({
            category: 'Checkin_Kiosk',
            action: 'Exit_Logout',
            value: 1
          })
          navigate('/')
        }}
      >
        <FontAwesomeIcon
          icon={faArrowRightFromBracket}
          className="text-white text-xl"
        />
        <span className="text-center text-sm underline">Exit</span>
      </div>
    </div>
  )
}

export default CheckinSidebar
