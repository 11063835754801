import React, {useEffect, useState} from 'react'
import {
  useGetVerificationStatusQuery,
  useSendCheckinNotificationMutation
} from '../../services/guestCheckinApi'
import {
  AutohostVerificationStatus,
  GuestVerificationNotification,
  NotificationType
} from '../../types'
import {getLogger} from '../../services/logging'
import StepLabel from '../common/StepLabel'
import ReactGA from 'react-ga4'

interface VerifyIdentityStatusProps {
  autohostReservationId: string
  onComplete: (isVerified: boolean) => void
}

const logger = getLogger('VerifyIdentityStatus')
const POLL_DELAY = 5000
const POLL_ATTEMPTS = 40

const VerifyIdentityStatus = ({
  autohostReservationId,
  onComplete
}: VerifyIdentityStatusProps) => {
  const [pollAttempts, setPollAttempts] = useState<number>(POLL_ATTEMPTS)
  const [sendCheckinNotification] = useSendCheckinNotificationMutation()
  const verificationStatusQuery = useGetVerificationStatusQuery(null)
  const verificationStatus =
    verificationStatusQuery.data?.autohostVerificationStatus
  const isVerificationComplete =
    verificationStatus === AutohostVerificationStatus.Verified ||
    verificationStatus === AutohostVerificationStatus.Rejected

  // send google analytics events
  useEffect(() => {
    ReactGA.event({
      category: 'Checkin_Kiosk',
      action: 'Guest_Verification_Status',
      value: 1
    })
  }, [])

  // send notification to OSO when verification is ready for review
  useEffect(() => {
    if (!isVerificationComplete) {
      logger.debug({
        message: 'Sending verification ready for review notification...'
      })
      sendCheckinNotification({
        type: NotificationType.VerificationReady,
        autohostReservationId
      } as GuestVerificationNotification)
    }
  }, [verificationStatus])

  // poll for verification status updates
  useEffect(() => {
    const {isLoading, refetch} = verificationStatusQuery

    logger.debug({
      message: 'Polling for identity verification status updates: ',
      data: {
        pollAttempts,
        verificationStatus,
        isLoading
      }
    })

    if (pollAttempts > 0 && !isLoading && !isVerificationComplete) {
      setTimeout(() => {
        logger.debug({message: 'Refetching identity verification status...'})
        refetch()
        setPollAttempts(pollAttempts - 1)
      }, POLL_DELAY)
    }

    if (pollAttempts <= 0 || isVerificationComplete) {
      logger.debug({
        message: 'Polling for identity verification complete',
        data: {verificationStatus}
      })
      const isVerified =
        verificationStatus === AutohostVerificationStatus.Verified
      onComplete(isVerified)
    }
  }, [pollAttempts, verificationStatusQuery.isLoading])

  return (
    <section className="w-1/2 flex flex-col items-center justify-start gap-8 mt-12 mx-auto text-center">
      <StepLabel label="2" />
      <h1 className="header-2 font-semibold">
        We are currently processing your guest verification
      </h1>
      <div className="h-full flex justify-center items-center my-8">
        <span
          className={`loading loading-spinner w-52 h-52 text-secondary`}
        ></span>
      </div>
      <p className="text-lg">
        This process can take a few minutes. We're working as hard as we can to
        get you checked-in as soon as possible.
      </p>
    </section>
  )
}

export default VerifyIdentityStatus
