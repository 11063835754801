import React, {useEffect} from 'react'
import CheckinPerson from '../../assets/checkin-person.png'
import StepLabel from '../common/StepLabel'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircleRight} from '@fortawesome/free-regular-svg-icons'
import ReactGA from 'react-ga4'

interface VerifyIdentityWelcomeProps {
  onNext: () => void
}

const VerifyIdentityWelcome = ({onNext}: VerifyIdentityWelcomeProps) => {
  useEffect(() => {
    ReactGA.event({
      category: 'Checkin_Kiosk',
      action: 'Guest_Verification_Welcome',
      value: 1
    })
  }, [])

  return (
    <section className="text-center mx-auto mt-12">
      <StepLabel label="2" />

      <h1 className="header-2">Guest Verification</h1>

      <h2 className="text-center px-24 my-2" style={{fontSize: 24}}>
        All guests are required to verify their identity. Please be ready with a
        government-issued ID or passport.
      </h2>

      <img src={CheckinPerson} className="mt-8 h-52 mx-auto" />
      <button
        className="btn btn-secondary btn-wide font-bold mt-8 text-xl"
        onClick={() => onNext()}
      >
        <FontAwesomeIcon icon={faCircleRight} />
        <span>continue</span>
      </button>
    </section>
  )
}

export default VerifyIdentityWelcome
