import React from 'react'

export interface NavyYardAccessInfoProps {
  roomNumber: string
  roomPin: string
  buildingPin: string
}

interface BuildingInfo {
  buildingName: string
  buildingPinLabel: string
}

function getBuildingInfo(roomNumber: string): BuildingInfo {
  if (roomNumber.startsWith('E')) {
    return {
      buildingName: 'East Building',
      buildingPinLabel: 'East Entrance PIN:'
    }
  } else if (roomNumber.startsWith('W')) {
    return {
      buildingName: 'West Building',
      buildingPinLabel: 'West Entrance PIN:'
    }
  } else {
    return {
      buildingName: ' - ',
      buildingPinLabel: ' - '
    }
  }
}

const NavyYardAccessInfo = ({
  roomNumber,
  roomPin,
  buildingPin
}: NavyYardAccessInfoProps) => {
  const {buildingName, buildingPinLabel} = getBuildingInfo(roomNumber)

  return (
    <table className="checkin-table text-lg">
      <tbody>
        <tr>
          <th>Building:</th>
          <td>{buildingName}</td>
        </tr>
        <tr>
          <th>{buildingPinLabel}</th>
          <td>{buildingPin}</td>
        </tr>
        <tr>
          <th>Apartment Number:</th>
          <td>{roomNumber}</td>
        </tr>
        <tr>
          <th>Room PIN:</th>
          <td>{roomPin ? `${roomPin}#` : ''}</td>
        </tr>
      </tbody>
    </table>
  )
}

export default NavyYardAccessInfo
