import {LogLevel} from '../types'

declare var process: {
  env: {
    [key: string]: string
  }
}

export interface AppConfig {
  adyenClientId: string
  adyenEnvironment: string
  checkinRulesEnabled: boolean
  environment: string
  googleAnalyticsId: string
  guestCheckinApiUrl: string
  heartbeatEnabled: boolean
  heartbeatInterval: number
  idleTimeout: number
  loggingApiUrl: string
  loggingEnabled: boolean
  loggingLevel: LogLevel
  metricsApiUrl: string
  metricsEnabled: boolean
  metricsNamespace: string
  reduxDevTools: boolean
}

export function getConfig(): AppConfig {
  return {
    adyenClientId: process.env.ADYEN_CLIENT_ID,
    adyenEnvironment: process.env.ADYEN_ENVIRONMENT,
    checkinRulesEnabled: process.env.CHECKIN_RULES_ENABLED === 'true',
    environment: process.env.ENVIRONMENT,
    googleAnalyticsId: process.env.GOOGLE_ANALYTICS_ID,
    guestCheckinApiUrl: process.env.GUEST_CHECKIN_API_URL,
    heartbeatEnabled: process.env.HEARTBEAT_ENABLED === 'true',
    heartbeatInterval: parseInt(process.env.HEARTBEAT_INTERVAL_MS, 10),
    idleTimeout: parseInt(process.env.IDLE_TIMEOUT_MS, 10),
    loggingApiUrl: process.env.LOGGING_API_URL,
    loggingEnabled: process.env.LOGGING_ENABLED === 'true',
    loggingLevel: process.env.LOGGING_LEVEL as LogLevel,
    metricsApiUrl: process.env.METRICS_API_URL,
    metricsEnabled: process.env.METRICS_ENABLED === 'true',
    metricsNamespace: process.env.METRICS_NAMESPACE,
    reduxDevTools: process.env.REDUX_DEV_TOOLS === 'true'
  }
}
