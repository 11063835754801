import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from './store'

export interface HelpState {
  isHelpOpen: boolean
}

// slice
export const helpSlice = createSlice({
  name: 'help',
  initialState: {
    isHelpOpen: false
  },
  reducers: {
    setIsHelpOpen: (state, action: PayloadAction<boolean>) => {
      state = {
        ...state,
        isHelpOpen: action.payload
      }
      return state
    }
  }
})

// selectors
export const selectIsHelpOpen = (state: RootState) => {
  return state.help?.isHelpOpen === true
}

// actions
export const {setIsHelpOpen} = helpSlice.actions

// reducer
export default helpSlice.reducer
