import {getConfig} from './config'
import {getLogger} from './logging'

const {metricsApiUrl, metricsEnabled, metricsNamespace} = getConfig()
const logger = getLogger('Metrics')

export enum Metric {
  ApplicationError = 'ApplicationError',
  AuthenticationCompleted = 'AuthenticationCompleted',
  AuthenticationFailed = 'AuthenticationFailed',
  CheckinCompleted = 'CheckinCompleted',
  CheckinError = 'CheckinError',
  CheckinStarted = 'CheckinStarted',
  Heartbeat = 'Heartbeat',
  IdentityVerificationCompleted = 'IdentityVerificationCompleted',
  ReservationConfirmed = 'ReservationConfirmed'
}

export enum MetricUnit {
  Count = 'Count'
}

export async function pushMetric(
  metricName: Metric,
  metricValue: number,
  metricDimensions?: Record<string, any>
): Promise<void> {
  if (!metricsEnabled) {
    return
  }

  const metricData = {
    namespace: metricsNamespace,
    name: metricName,
    timestamp: new Date().toISOString(),
    unit: MetricUnit.Count,
    value: metricValue,
    dimensions: metricDimensions
  }
  const metricJson = JSON.stringify(metricData)

  try {
    await fetch(metricsApiUrl, {
      method: 'post',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept': '*/*'
      }),
      body: metricJson
    })
  } catch (e) {
    logger.error({
      message: 'Failed to push metric',
      data: {metricData, metricsApiUrl},
      errorData: {error: e}
    })
  }
}
