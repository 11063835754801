import React from 'react'

export enum LoadingSpinnerSize {
  Large = 'lg',
  Medium = 'md',
  Small = 'sm'
}

export interface LoadingSpinnerProps {
  size: LoadingSpinnerSize
}

export default function LoadingSpinner({
  size = LoadingSpinnerSize.Large
}: LoadingSpinnerProps) {
  return <span className={`loading loading-spinner loading-${size}`}></span>
}
