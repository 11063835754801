import React, {useEffect, useRef} from 'react'
import CheckinRoom from '../../assets/checkin-room.png'
import {useNavigate} from 'react-router-dom'
import StepLabel from '../common/StepLabel'
import {getLogger} from '../../services/logging'
import {faCheckCircle} from '@fortawesome/free-regular-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import ReactGA from 'react-ga4'
import {getPropertyConfig} from '../../services/propertyContext'
import {useSendCheckinNotificationMutation} from '../../services/guestCheckinApi'
import {NotificationType, ReservationCheckin} from '../../types'
import DefaultCheckinDirections from './DefaultCheckinDirections'

export interface ViewCheckinDirectionsProps {
  reservationCheckin: ReservationCheckin
}

const COMPLETE_CHECKIN_TIMEOUT = 30
const logger = getLogger('ViewCheckinDirections')

const ViewCheckinDirections = ({
  reservationCheckin
}: ViewCheckinDirectionsProps) => {
  const navigate = useNavigate()
  const timerRef = useRef(null)
  const secondsRef = useRef(COMPLETE_CHECKIN_TIMEOUT)
  const [sendCheckinNotification] = useSendCheckinNotificationMutation()
  const {CustomCheckinDirectionsComponent} = getPropertyConfig()

  useEffect(() => {
    ReactGA.event({
      category: 'Checkin_Kiosk',
      action: 'Complete_Checkin',
      value: 1
    })
    sendCheckinNotification({
      type: NotificationType.CheckinCompleted
    })
  }, [])

  useEffect(() => {
    if (!timerRef.current) {
      timerRef.current = setInterval(() => {
        const secondsRemaining = secondsRef.current - 1
        secondsRef.current = secondsRemaining
        if (secondsRemaining <= 0) {
          logger.debug({message: 'Checkin complete inactivity timeout'})

          clearInterval(timerRef.current)
          navigate('/')
        }
      }, 1000)
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current)
      }
    }
  }, [])

  return (
    <section className="w-full mt-12 flex flex-col items-center justify-start gap-8">
      <div className="flex flex-col items-center gap-2">
        <StepLabel label="4" />
        <h1 className="header-2 text-center">
          <span className="font-bold">You're all set!</span>
          <br />
          Have a Great Stay!
        </h1>
      </div>
      <img src={CheckinRoom} className="h-52" />
      <div className="text-center w-2/3">
        {CustomCheckinDirectionsComponent
          ? CustomCheckinDirectionsComponent({reservationCheckin})
          : DefaultCheckinDirections()}
      </div>
      <button
        className="btn btn-secondary font-bold text-xl"
        onClick={() => {
          navigate('/')
        }}
      >
        <FontAwesomeIcon icon={faCheckCircle} />
        <span>complete check-in</span>
      </button>
    </section>
  )
}

export default ViewCheckinDirections
