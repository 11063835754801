import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from './store'
import {User} from '../types'

// slice
export const userSlice = createSlice({
  name: 'user',
  initialState: null,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state = action.payload
      return state
    }
  }
})

// selectors
export const selectUser = (state: RootState): User => state.user

// actions
export const {setUser} = userSlice.actions

// reducer
export default userSlice.reducer
