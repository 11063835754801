import React, {useEffect} from 'react'
import {useAppDispatch, useAppSelector} from '../../store/hooks'
import {selectIsHelpOpen, setIsHelpOpen} from '../../store/helpSlice'
import HelpModal from '../help/HelpModal'
import {CheckinErrorCode} from '../../types'
import {useNavigate, useSearchParams} from 'react-router-dom'
import CheckinError from '../../assets/checkin-error.png'
import CheckinPhone from '../../assets/checkin-phone.png'
import CheckinWarning from '../../assets/checkin-warning.png'
import {getLogger} from '../../services/logging'
import {faCircleQuestion} from '@fortawesome/free-regular-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Metric, pushMetric} from '../../services/metrics'
import {getPropertyId} from '../../services/propertyContext'

interface ErrorMessage {
  title: string
  description: string
}

const logger = getLogger('CheckinErrorPage')

function getErrorMessage(code: CheckinErrorCode): ErrorMessage {
  switch (code) {
    case CheckinErrorCode.CheckinDateInvalid:
      return {
        title: `We're sorry, it's not time to check in yet.`,
        description: `If you think this is an error, please contact us for help.`
      }
    case CheckinErrorCode.ReservationNotFound:
      return {
        title: `Oops!  We can't find that reservation.`,
        description:
          `Please try submitting your information again.  ` +
          `If you continue to have issues, please contact us for help.`
      }
    case CheckinErrorCode.ReservationCheckedIn:
      return {
        title: `This reservation has already been checked-in.`,
        description: `If you need assistance with your reservation, please contact us for help.`
      }
    case CheckinErrorCode.ReservationCheckedOut:
      return {
        title: `This reservation has already been checked-out.`,
        description:
          `If you need to extend your stay, or make a new reservation, book now at ` +
          `Placemakr.com or reach out to us for help.`
      }
    case CheckinErrorCode.ReservationCanceled:
      return {
        title: `This reservation has been canceled.`,
        description: `If you need assistance with your reservation, please contact us for help.`
      }

    case CheckinErrorCode.CheckinNotFound:
    case CheckinErrorCode.PinNotAssigned:
    case CheckinErrorCode.UnitNotAssigned:
    case CheckinErrorCode.CheckinPropertyInvalid:
    case CheckinErrorCode.ReservationNoShow:
    case CheckinErrorCode.ReservationInvalidStatus:
    case CheckinErrorCode.IdentityVerificationInvalid:
    case CheckinErrorCode.IdentityVerificationDeclined:
      return {
        title: `We're sorry, there is an issue with this reservation.`,
        description: `If you think this is an error, please contact us for help.`
      }
    default:
      logger.warn({message: 'Invalid error code', data: {code}})
      return {
        title: `We're sorry, there is an issue with this reservation.`,
        description: `If you think this is an error, please contact us for help.`
      }
  }
}

function getErrorIcon(code: CheckinErrorCode): string {
  switch (code) {
    case CheckinErrorCode.PinNotAssigned:
    case CheckinErrorCode.UnitNotAssigned:
    case CheckinErrorCode.IdentityVerificationInvalid:
      return CheckinWarning
    case CheckinErrorCode.ReservationCheckedIn:
    case CheckinErrorCode.ReservationCheckedOut:
      return CheckinPhone
    default:
      return CheckinError
  }
}

const CheckinErrorPage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isHelpOpen = useAppSelector(selectIsHelpOpen)
  const [searchParams] = useSearchParams()
  const errorCode = searchParams.get('code') as CheckinErrorCode
  const errorMessage = getErrorMessage(errorCode)
  const errorIcon = getErrorIcon(errorCode)

  useEffect(() => {
    // push reservation checkin error metric
    pushMetric(Metric.CheckinError, 1, {
      Property: getPropertyId()
    })
  }, [])

  return (
    <>
      <div
        className={
          'max-w-screen-xl mx-auto min-h-screen bg-white flex flex-col ' +
          'items-center justify-center gap-8 px-24 text-center'
        }
      >
        <h1 className="header-2 font-semibold">Check-in Error</h1>
        <img src={errorIcon} className="h-52" />
        <h2 className="text-2xl font-semibold">{errorMessage.title}</h2>
        <p className="text-xl">{errorMessage.description}</p>
        <button
          className="btn btn-secondary btn-wide font-bold text-xl mt-8"
          onClick={() => dispatch(setIsHelpOpen(true))}
        >
          <FontAwesomeIcon icon={faCircleQuestion} />
          <span>get help</span>
        </button>

        <div
          className="w-full text-center cursor-pointer text-md text-midnight underline"
          onClick={() => {
            navigate('/')
          }}
        >
          back to home screen
        </div>
      </div>
      <HelpModal
        isOpen={isHelpOpen}
        onClose={() => {
          dispatch(setIsHelpOpen(false))
        }}
      />
    </>
  )
}

export default CheckinErrorPage
