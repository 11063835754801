import React, {useEffect, useState} from 'react'
import clsx from 'clsx'
import LoadingSpinner, {LoadingSpinnerSize} from '../common/LoadingSpinner'
import {addDays, format, isAfter, isValid, subDays} from 'date-fns'
import {
  FindReservationResult,
  LoginLookupFailureNotification,
  NotificationType
} from '../../types'
import {
  useFindReservationQuery,
  useSendLoginNotificationMutation
} from '../../services/guestCheckinApi'
import {getLogger} from '../../services/logging'
import {faSearch} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import DatePickerInput from '../common/DatePickerInput'
import ReactGA from 'react-ga4'
import {getPropertyId} from '../../services/propertyContext'

export interface FindReservationFormProps {
  onReservationsFound: (reservations: FindReservationResult[]) => void
  onReservationsNotFound: () => void
}

interface FormField {
  value: any
  isDirty: boolean
  isValid: boolean
  error: string
}

interface FindReservationFormState {
  firstName: FormField
  lastName: FormField
  phoneLast4: FormField
  checkinDate: FormField
  checkoutDate: FormField
  isValid: boolean
  isSubmitting: boolean
}

const logger = getLogger('FindReservationForm')

function validateForm(
  form: FindReservationFormState
): FindReservationFormState {
  const {firstName, lastName, phoneLast4, checkinDate, checkoutDate} = form

  // validate first name
  const isFirstNameValid = firstName?.value?.length > 0
  const validatedFirstName = {
    ...firstName,
    isValid: isFirstNameValid,
    error: isFirstNameValid ? '' : 'First name is required.'
  }

  // validate last name
  const isLastNameValid = lastName?.value?.length > 0
  const validatedLastName = {
    ...lastName,
    isValid: isLastNameValid,
    error: isLastNameValid ? '' : 'Last name is required.'
  }

  // validate phone number
  const isPhoneLast4Valid = phoneLast4?.value?.length === 4
  const validatedPhoneLast4 = {
    ...phoneLast4,
    isValid: isPhoneLast4Valid,
    error: isPhoneLast4Valid
      ? ''
      : 'Last 4 digits of phone number are required.'
  }

  // validate checkin date
  const isCheckinDateValid = isValid(checkinDate?.value)
  const validatedCheckinDate = {
    ...checkinDate,
    isValid: isCheckinDateValid,
    error: isCheckinDateValid ? '' : 'A valid check-in date is required.'
  }

  // validate checkout date
  const isCheckoutDateValid =
    isValid(checkoutDate?.value) &&
    isAfter(checkoutDate?.value, checkinDate?.value)
  const validatedCheckoutDate = {
    ...checkoutDate,
    isValid: isCheckoutDateValid,
    error: isCheckoutDateValid
      ? ''
      : 'A check-out date that is after the check-in date is required.'
  }

  return {
    ...form,
    firstName: validatedFirstName,
    lastName: validatedLastName,
    phoneLast4: validatedPhoneLast4,
    checkinDate: validatedCheckinDate,
    checkoutDate: validatedCheckoutDate,
    isValid:
      isFirstNameValid &&
      isLastNameValid &&
      isPhoneLast4Valid &&
      isCheckinDateValid &&
      isCheckoutDateValid
  }
}

const defaultForm: FindReservationFormState = {
  firstName: {
    value: '',
    isDirty: false,
    isValid: false,
    error: ''
  },
  lastName: {
    value: '',
    isDirty: false,
    isValid: false,
    error: ''
  },
  phoneLast4: {
    value: '',
    isDirty: false,
    isValid: false,
    error: ''
  },
  checkinDate: {
    value: '',
    isDirty: false,
    isValid: false,
    error: ''
  },
  checkoutDate: {
    value: '',
    isDirty: false,
    isValid: false,
    error: ''
  },
  isValid: false,
  isSubmitting: false
}

export default function FindReservationForm(props: FindReservationFormProps) {
  const {onReservationsNotFound, onReservationsFound} = props
  const [findReservationForm, setFindReservationForm] = useState<
    FindReservationFormState
  >(defaultForm)
  const form = validateForm(findReservationForm)
  const {firstName, lastName, phoneLast4, checkinDate, checkoutDate} = form
  const params = {
    propertyId: getPropertyId(),
    firstName: firstName?.value?.trim(),
    lastName: lastName?.value?.trim(),
    phoneLast4: phoneLast4?.value,
    checkinDate: checkinDate?.value
      ? format(checkinDate?.value, 'yyyy-MM-dd')
      : '',
    checkoutDate: checkoutDate?.value
      ? format(checkoutDate?.value, 'yyyy-MM-dd')
      : ''
  }
  const findReservationQuery = useFindReservationQuery(params, {
    skip: !findReservationForm.isSubmitting
  })
  const [sendLoginNotification] = useSendLoginNotificationMutation()
  const today = new Date()

  useEffect(() => {
    if (!findReservationQuery.isLoading) {
      // add delay so that button has time to reflect loading state
      setTimeout(() => {
        setFindReservationForm({
          ...form,
          isSubmitting: false
        })
      }, 1000)
    }

    // failed to find reservations
    if (findReservationQuery.error) {
      logger.warn({
        message: 'Login failure with reservation lookup',
        data: {params, error: findReservationQuery?.error}
      })

      sendLoginNotification({
        type: NotificationType.LoginLookupFailed,
        propertyId: getPropertyId(),
        firstName: firstName?.value,
        lastName: lastName?.value,
        phoneLast4: phoneLast4?.value,
        checkinDate: format(checkinDate?.value, 'MM-dd-yyyy'),
        checkoutDate: format(checkoutDate?.value, 'MM-dd-yyyy')
      } as LoginLookupFailureNotification)

      ReactGA.event({
        category: 'checkin_kiosk',
        action: 'Reservation_Lookup_Login_Failure',
        value: 1
      })

      onReservationsNotFound()
    }

    // found reservations
    if (findReservationQuery.data) {
      ReactGA.event({
        category: 'Checkin_Kiosk',
        action: 'Reservation_Lookup_Login_Success',
        value: 1
      })

      onReservationsFound(findReservationQuery.data)
    }
  }, [findReservationQuery.data, findReservationQuery.error])

  return (
    <div>
      <form className="flex flex-col gap-4 mt-6">
        <div className="form-control w-full">
          <input
            type="text"
            placeholder="First name"
            className="w-full checkin-input"
            value={firstName?.value}
            onChange={e => {
              setFindReservationForm({
                ...form,
                firstName: {
                  ...firstName,
                  isDirty: true,
                  value: e.currentTarget.value
                }
              })
            }}
          />
          <div
            className={clsx('label', {
              'hidden': !firstName?.isDirty || firstName?.isValid
            })}
          >
            <span className="label-text-alt text-red-500">
              {firstName?.error}
            </span>
          </div>
        </div>

        <div className="form-control w-full">
          <input
            type="text"
            placeholder="Last name"
            className="w-full checkin-input"
            value={lastName?.value}
            onChange={e => {
              setFindReservationForm({
                ...form,
                lastName: {
                  ...lastName,
                  isDirty: true,
                  value: e.currentTarget.value
                }
              })
            }}
          />
          <div
            className={clsx('label', {
              'hidden': !lastName?.isDirty || lastName?.isValid
            })}
          >
            <span className="label-text-alt text-red-500">
              {lastName?.error}
            </span>
          </div>
        </div>

        <div className="form-control w-full">
          <input
            type="text"
            placeholder="Last 4 digits of phone number"
            className="w-full checkin-input"
            value={phoneLast4?.value}
            onChange={e => {
              const value = e.currentTarget.value.trim()
              setFindReservationForm({
                ...form,
                phoneLast4: {
                  ...form?.phoneLast4,
                  isDirty: true,
                  value: value.length > 4 ? value.substring(0, 4) : value
                }
              })
            }}
          />
          <div
            className={clsx('label', {
              'hidden': !phoneLast4?.isDirty || phoneLast4?.isValid
            })}
          >
            <span className="label-text-alt text-red-500">
              {phoneLast4?.error}
            </span>
          </div>
        </div>

        <div className="form-control w-full">
          <DatePickerInput
            value={checkinDate?.value}
            minDate={subDays(today, 1)}
            maxDate={today}
            onChange={(date): void => {
              setFindReservationForm({
                ...form,
                checkinDate: {
                  ...form?.checkinDate,
                  isDirty: true,
                  value: date
                }
              })
            }}
            placeholder="Check-in date"
          />
          <div
            className={clsx('label', {
              'hidden': !checkinDate?.isDirty || checkinDate?.isValid
            })}
          >
            <span className="label-text-alt text-red-500">
              {checkinDate?.error}
            </span>
          </div>
        </div>

        <div className="form-control w-full">
          <DatePickerInput
            value={checkoutDate?.value}
            minDate={checkinDate?.value ? addDays(checkinDate?.value, 1) : null}
            onChange={(date): void => {
              setFindReservationForm({
                ...form,
                checkoutDate: {
                  ...checkoutDate,
                  isDirty: true,
                  value: date
                }
              })
            }}
            placeholder="Check-out date"
          />
          <div
            className={clsx('label', {
              'hidden': !checkoutDate?.isDirty || checkoutDate?.isValid
            })}
          >
            <span className="label-text-alt text-red-500">
              {checkoutDate?.error}
            </span>
          </div>
        </div>
      </form>

      <button
        className="btn btn-secondary flex flex-row justify-center items-center w-full gap-2 mt-6"
        disabled={!form.isValid || form.isSubmitting}
        onClick={() => {
          setFindReservationForm({
            ...form,
            isSubmitting: true
          })

          ReactGA.event({
            category: 'Checkin_Kiosk',
            action: 'Reservation_Lookup_Login',
            value: 1
          })
        }}
      >
        <div className={clsx({hidden: !form.isSubmitting})}>
          <LoadingSpinner size={LoadingSpinnerSize.Small} />
        </div>
        <div className="text-center">
          <FontAwesomeIcon icon={faSearch} className="mr-3" />
          {form.isSubmitting ? 'finding reservation...' : 'find my reservation'}
        </div>
      </button>
    </div>
  )
}
