import React, {useEffect, useRef, useState} from 'react'
import CheckinPin from '../../assets/checkin-pin.png'
import {useCheckinGuestMutation} from '../../services/guestCheckinApi'
import LoadingSpinner, {LoadingSpinnerSize} from '../common/LoadingSpinner'
import {useAppDispatch} from '../../store/hooks'
import {setIsHelpOpen} from '../../store/helpSlice'
import {getLogger} from '../../services/logging'
import {getPropertyConfig, getPropertyId} from '../../services/propertyContext'
import {Metric, pushMetric} from '../../services/metrics'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faXmark} from '@fortawesome/free-solid-svg-icons'
import {
  faCheckCircle,
  faQuestionCircle
} from '@fortawesome/free-regular-svg-icons'
import ReactGA from 'react-ga4'
import DefaultAccessInfo from './DefaultAccessInfo'

export interface ViewAccessInfoModalStatus {
  hasErrors: boolean
  hasBeenViewed: boolean
}

interface ViewAccessInfoModalProps {
  isOpen: boolean
  onClose: (status: ViewAccessInfoModalStatus) => void
}

enum ViewState {
  Loading = 'Loading',
  Error = 'Error',
  DisclosureInfo = 'DisclosureInfo',
  AccessInfo = 'AccessInfo',
  AccessInfoTimeout = 'AccessInfoTimeout'
}

const logger = getLogger('ViewAccessInfoModal')

const ROOM_DETAIL_TIMEOUT = 25

const ViewAccessInfoModal = ({isOpen, onClose}: ViewAccessInfoModalProps) => {
  const dispatch = useAppDispatch()
  const [checkinGuest, checkinGuestMutation] = useCheckinGuestMutation()
  const roomDetails = checkinGuestMutation?.data
  const modalElement = useRef<any>(null)
  const timerRef = useRef(null)
  const secondsRef = useRef(ROOM_DETAIL_TIMEOUT)
  const [viewState, setViewState] = useState(ViewState.Loading)
  const [secondsRemaining, setSecondsRemaining] = useState(secondsRef.current)
  const hasBeenViewed = [
    ViewState.AccessInfo,
    ViewState.AccessInfoTimeout
  ].includes(viewState)
  const {CustomAccessInfoComponent} = getPropertyConfig()

  // modal open/closed
  useEffect(() => {
    if (modalElement.current) {
      const modal = modalElement.current
      if (isOpen) {
        modal.showModal()
      } else {
        modal.close()
      }
    }
  }, [isOpen])

  // trigger guest checkin
  useEffect(() => {
    if (isOpen) {
      if (!roomDetails) {
        logger.debug({
          message: 'Performing guest checkin and retrieving room details...'
        })

        setViewState(ViewState.Loading)
        checkinGuest(null)
          .unwrap()
          .then(() => {
            setViewState(ViewState.DisclosureInfo)
            pushMetric(Metric.CheckinCompleted, 1, {Property: getPropertyId()})
          })
          .catch(e => {
            logger.error({
              message: 'Guest checkin failed',
              data: {
                error: e
              }
            })
            onClose({hasErrors: true, hasBeenViewed})
          })
      } else {
        setViewState(ViewState.DisclosureInfo)
      }
    }
  }, [isOpen, roomDetails])

  // view state - timer
  useEffect(() => {
    if (viewState === ViewState.AccessInfo && !timerRef.current) {
      timerRef.current = setInterval(() => {
        const secondsRemaining = secondsRef.current - 1

        secondsRef.current = secondsRemaining
        setSecondsRemaining(secondsRemaining)

        if (secondsRemaining <= 0) {
          clearInterval(timerRef.current)
          setViewState(ViewState.AccessInfoTimeout)

          logger.debug({
            message: 'Room details display timed out',
            data: {
              viewState
            }
          })
        }
      }, 1000)
    }
  }, [viewState, timerRef.current])

  logger.debug({
    message: 'View state changed',
    data: {
      viewState
    }
  })

  return (
    <dialog ref={modalElement} className="modal">
      <div className="modal-box flex flex-col items-center justify-center gap-4 p-4">
        <div className="w-full grid justify-items-end">
          <button
            className="btn btn-ghost btn-circle border-0 focus:outline-none"
            onClick={() => {
              onClose({hasErrors: false, hasBeenViewed})
            }}
          >
            <FontAwesomeIcon icon={faXmark} className="text-xl" />
          </button>
        </div>

        {viewState === ViewState.Loading ? (
          <div className="flex justify-center items-center h-80 gap-2">
            <LoadingSpinner size={LoadingSpinnerSize.Large} />
            <div>Loading room details...</div>
          </div>
        ) : (
          ''
        )}

        {viewState === ViewState.DisclosureInfo ? (
          <>
            <img src={CheckinPin} className="h-52" />
            <h1 className="header-3">Get ready for your PIN</h1>
            <p className="mx-8 mb-8 text-lg">
              Please note,{' '}
              <span className="font-bold">
                you will only be able to access this screen once,{' '}
              </span>
              and it will automatically close after{' '}
              <span className="font-bold">{ROOM_DETAIL_TIMEOUT} seconds</span>.
              <br />
              <br />
              For your convenience, there is paper and pen attached to the neck
              of the kiosk below.
            </p>
            <button
              className="btn btn-secondary btn-wide mb-12"
              onClick={() => {
                setViewState(ViewState.AccessInfo)

                ReactGA.event({
                  category: 'Checkin_Kiosk',
                  action: 'Checkin_Details_View_Complete',
                  value: 1
                })
              }}
            >
              <FontAwesomeIcon icon={faCheckCircle} />
              <span>i'm ready</span>
            </button>
          </>
        ) : (
          ''
        )}
        {viewState === ViewState.AccessInfo ? (
          <>
            <h1 className="header-2 font-black">{secondsRemaining}</h1>
            <h1 className="header-3">Your unit and PIN information</h1>
            <div className="mx-8 my-2">
              Please don't close until you're sure you've copied your room
              information correctly. You will not be able to view it again.
            </div>
            <div className="mx-8 p-8 bg-gray-100 rounded-lg">
              {CustomAccessInfoComponent
                ? CustomAccessInfoComponent(roomDetails)
                : DefaultAccessInfo(roomDetails)}
            </div>
            <button
              className="btn btn-secondary btn-wide focus:outline-none mb-12"
              onClick={() => {
                onClose({hasErrors: false, hasBeenViewed})
              }}
            >
              <FontAwesomeIcon icon={faCheckCircle} />
              <span>done</span>
            </button>
          </>
        ) : (
          ''
        )}

        {viewState === ViewState.AccessInfoTimeout ? (
          <>
            <h1 className="header-3 text-center">Time's Up!</h1>
            <p className="mx-8 mb-8 text-center text-lg">
              For security reasons, we can no longer display unit and PIN
              information after {ROOM_DETAIL_TIMEOUT} seconds. If you need
              assistance, please reach out to support.
            </p>
            <div className="flex flex-row w-full justify-around mb-12">
              <button
                className="btn btn-outline btn-secondary focus:outline-none"
                onClick={() => dispatch(setIsHelpOpen(true))}
              >
                <FontAwesomeIcon icon={faQuestionCircle} />
                <span>get help</span>
              </button>
              <button
                className="btn btn-secondary focus:outline-none"
                onClick={() => {
                  onClose({hasErrors: false, hasBeenViewed})
                }}
              >
                <FontAwesomeIcon icon={faCheckCircle} />
                <span>done</span>
              </button>
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    </dialog>
  )
}

export default ViewAccessInfoModal
