import React from 'react'
import {formatDateString} from '../../services/utils'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faCircleLeft,
  faCircleCheck,
  faCalendar
} from '@fortawesome/free-regular-svg-icons'
import StepLabel from '../common/StepLabel'
import {FindReservationResult} from '../../types'
import ReactGA from 'react-ga4'
import {faBed, faReceipt} from '@fortawesome/free-solid-svg-icons'

interface FindReservationResultsProps {
  reservations: FindReservationResult[]
  onCheckinReservation: (reservation: FindReservationResult) => void
  onCancel: () => void
}

interface FindReservationResultItemProps {
  reservation: FindReservationResult
  onSelect: () => void
}

function FindReservationResultItem({
  reservation,
  onSelect
}: FindReservationResultItemProps) {
  return (
    <div className="flex flex-row px-8 py-4 bg-gray-100 w-full rounded-lg">
      <div className="flex flex-col gap-1 w-full">
        <h3 className="header-3">
          {reservation.firstName} {reservation.lastName}
        </h3>
        <div className="flex flew-row gap-2">
          <span className="w-6">
            <FontAwesomeIcon icon={faReceipt} />
          </span>
          <span>{reservation.reservationId}</span>
        </div>
        <div className="flex flew-row gap-2">
          <span className="w-6">
            <FontAwesomeIcon icon={faBed} />
          </span>
          <span>{reservation.unitType}</span>
        </div>
        <div className="flex flew-row gap-2">
          <span className="w-6">
            <FontAwesomeIcon icon={faCalendar} />
          </span>
          <span>
            {formatDateString(reservation.checkinDate)} -{' '}
            {formatDateString(reservation.checkoutDate)}
          </span>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <button
          className="btn btn-secondary w-52 mt-2"
          onClick={() => onSelect()}
        >
          <FontAwesomeIcon icon={faCircleCheck} />
          <span>check in</span>
        </button>
      </div>
    </div>
  )
}

const FindReservationResults = ({
  reservations,
  onCancel,
  onCheckinReservation
}: FindReservationResultsProps) => {
  return (
    <section className="w-5/6 mt-12">
      <StepLabel label="1" />

      <h1 className="header-2 text-center">Please select your reservation</h1>

      <div className="flex flex-col gap-2 mt-8 p-2 border max-h-[520px] overflow-y-scroll">
        {reservations.map((reservation, index) => (
          <FindReservationResultItem
            key={index}
            reservation={reservation}
            onSelect={() => {
              onCheckinReservation(reservation)
            }}
          />
        ))}
      </div>

      <div className="flex flex-row w-full mt-12 items-center justify-center">
        <button
          className="btn btn-secondary btn-outline"
          onClick={() => {
            ReactGA.event({
              category: 'Checkin_Kiosk',
              action: 'Reservation_Confirm_Cancel',
              value: 1
            })
            onCancel()
          }}
        >
          <FontAwesomeIcon icon={faCircleLeft} />
          <span>can't find my reservation</span>
        </button>
      </div>
    </section>
  )
}

export default FindReservationResults
