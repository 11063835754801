import React, {useEffect, useState} from 'react'
import {
  FindReservationResult,
  GuestCredentialsType,
  LoginCodeFailureNotification,
  NotificationType
} from '../../types'
import {
  getGuestUser,
  removeGuestToken,
  setGuestToken
} from '../../services/guestToken'
import {setAuthenticated} from '../../store/authSlice'
import {setUser} from '../../store/userSlice'
import {useAppDispatch} from '../../store/hooks'
import StepLabel from '../common/StepLabel'
import {setReservation} from '../../store/reservationSlice'
import {useNavigate} from 'react-router-dom'
import {setIsHelpOpen} from '../../store/helpSlice'
import {getLogger} from '../../services/logging'
import {Metric, pushMetric} from '../../services/metrics'
import {getPropertyId} from '../../services/propertyContext'
import ReactGA from 'react-ga4'
import {
  useLoginMutation,
  useSendLoginNotificationMutation
} from '../../services/guestCheckinApi'
import FindReservationForm from './FindReservationForm'
import FindReservationResults from './FindReservationResults'
import {ThunkDispatch} from '@reduxjs/toolkit'
import LoadingSpinner, {LoadingSpinnerSize} from '../common/LoadingSpinner'

enum ViewState {
  Form = 'Form',
  FormError = 'FormError',
  Results = 'Results',
  Loading = 'Loading'
}

interface FindReservationState {
  view: ViewState
  reservations?: FindReservationResult[]
}

interface FindReservationFormHeaderProps {
  view: ViewState
  dispatch: ThunkDispatch<any, any, any>
}

const logger = getLogger('FindReservation')

function FindReservationFormHeader({
  view,
  dispatch
}: FindReservationFormHeaderProps) {
  return (
    <>
      <StepLabel label="1" />
      {view !== ViewState.FormError ? (
        <>
          <h1 className="header-2">
            Let's look up
            <br /> your reservation.
          </h1>
        </>
      ) : (
        <>
          <h1 className="header-2">
            Oops!
            <br />
            We can't find that reservation
          </h1>
          <div className="text-md mt-2">
            Try submitting your information again. If you continue to have
            issues, please
            <a
              className="underline ml-1 cursor-pointer"
              onClick={() => dispatch(setIsHelpOpen(true))}
            >
              contact us
            </a>
            .
          </div>
        </>
      )}
    </>
  )
}

const FindReservation = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [login] = useLoginMutation()
  const [sendLoginNotification] = useSendLoginNotificationMutation()
  const [findReservationState, setFindReservationState] = useState<
    FindReservationState
  >({
    view: ViewState.Form,
    reservations: null
  })

  // send google analytics event
  useEffect(() => {
    ReactGA.event({
      category: 'Checkin_Kiosk',
      action: 'Guest_Login',
      value: 1
    })
  }, [])

  const {view} = findReservationState

  if (view === ViewState.Form || view === ViewState.FormError) {
    return (
      <section className="w-full flex items-start justify-center mt-12">
        <div className="text-center max-w-lg">
          <FindReservationFormHeader view={view} dispatch={dispatch} />
          <FindReservationForm
            onReservationsFound={(reservations: FindReservationResult[]) => {
              setFindReservationState({
                ...findReservationState,
                reservations,
                view: ViewState.Results
              })
            }}
            onReservationsNotFound={() => {
              logger.warn({
                message: `Login unsuccessful using reservation lookup`
              })

              pushMetric(Metric.AuthenticationFailed, 1, {
                Property: getPropertyId()
              })

              setFindReservationState({
                ...findReservationState,
                view: ViewState.FormError
              })
            }}
          />
        </div>
      </section>
    )
  } else if (view === ViewState.Results) {
    return (
      <section className="w-full h-full flex items-start justify-center">
        <FindReservationResults
          reservations={findReservationState.reservations}
          onCheckinReservation={reservation => {
            setFindReservationState({
              ...findReservationState,
              view: ViewState.Loading
            })

            ReactGA.event({
              category: 'Checkin_Kiosk',
              action: 'Confirmation_Code_Login',
              value: 1
            })

            const credentials = {
              type: GuestCredentialsType.ConfirmationCode,
              confirmationCode: reservation.confirmationCode,
              lastName: reservation.lastName
            }

            login(credentials)
              .unwrap()
              .then(({token}) => {
                ReactGA.event({
                  category: 'Checkin_Kiosk',
                  action: 'Confirmation_Code_Login_Success',
                  value: 1
                })

                // set guest token cookie
                setGuestToken(token)

                const guestUser = getGuestUser(token)
                const {
                  email,
                  role,
                  propertyId,
                  bookingId,
                  reservationId
                } = guestUser

                logger.info({
                  message: `Login successful for reservation ${reservationId} using confirmation code`,
                  data: {token, guestUser}
                })
                pushMetric(Metric.AuthenticationCompleted, 1, {
                  Property: propertyId
                })

                // set user
                dispatch(
                  setUser({
                    email,
                    role
                  })
                )

                // set reservation context
                dispatch(
                  setReservation({
                    propertyId,
                    bookingId,
                    reservationId,
                    isConfirmed: false
                  })
                )

                // set authenticated flag
                dispatch(setAuthenticated(true))
              })
              .catch(e => {
                logger.warn({
                  message: 'Login failure with confirmation code',
                  data: {credentials, error: e}
                })

                // send google analytics event
                ReactGA.event({
                  category: 'Checkin_Kiosk',
                  action: 'Confirmation_Code_Login_Failure',
                  value: 1
                })

                // send failed login notification
                sendLoginNotification({
                  type: NotificationType.LoginCodeFailed,
                  propertyId: getPropertyId(),
                  confirmationCode: reservation.confirmationCode,
                  lastName: reservation.lastName
                } as LoginCodeFailureNotification)
              })
          }}
          onCancel={() => {
            logger.info({message: 'User did not find reservation.'})
            dispatch(setAuthenticated(false))
            dispatch(setUser(null))
            dispatch(setReservation(null))
            removeGuestToken()
            navigate('/')
          }}
        />
      </section>
    )
  } else if (view === ViewState.Loading) {
    return (
      <div className="h-full w-full flex flex-row justify-center items-center gap-2">
        <LoadingSpinner size={LoadingSpinnerSize.Large} />
        <div>Loading Reservation...</div>
      </div>
    )
  } else {
    return <></>
  }
}

export default FindReservation
