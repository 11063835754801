import React from 'react'

export interface StepLabelProps {
  label: string
}

const StepLabel = ({label}: StepLabelProps) => {
  return (
    <div className="flex flex-row justify-center mb-4">
      <div className="flex flex-row justify-center items-center bg-secondary p-4 w-16 h-16 rounded-full">
        <div className="font-bold text-2xl">{label}</div>
      </div>
    </div>
  )
}

export default StepLabel
