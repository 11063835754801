import Cookies from 'js-cookie'
import {GuestUser} from '../types'

export interface GuestTokenClaims {
  sub: string
  property_id: string
  booking_id: string
  reservation_id: string
  role: string
}

export const GUEST_TOKEN_COOKIE = 'guest-token'

export function getGuestToken(): string {
  return Cookies.get(GUEST_TOKEN_COOKIE)
}

export function setGuestToken(token: string): void {
  Cookies.set(GUEST_TOKEN_COOKIE, token)
}

export function removeGuestToken(): void {
  Cookies.remove(GUEST_TOKEN_COOKIE)
}

export function parseJwt(token: string): GuestTokenClaims {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(c => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

export function getGuestUser(token: string): GuestUser {
  const {sub, property_id, booking_id, reservation_id, role} = parseJwt(token)
  return {
    email: sub,
    role,
    propertyId: property_id,
    bookingId: booking_id,
    reservationId: reservation_id
  }
}
