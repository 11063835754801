import React, {useEffect, useRef, useState} from 'react'

export interface DefaultAccessInfoProps {
  roomNumber: string
  roomPin: string
}

const DefaultAccessInfo = ({roomNumber, roomPin}: DefaultAccessInfoProps) => {
  return (
    <table className="checkin-table text-lg">
      <tbody>
        <tr>
          <th>Room Number:</th>
          <td>{roomNumber}</td>
        </tr>
        <tr>
          <th>Room PIN:</th>
          <td>{roomPin ? `${roomPin}#` : ''}</td>
        </tr>
      </tbody>
    </table>
  )
}

export default DefaultAccessInfo
