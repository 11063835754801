import Cookies from 'js-cookie'
import {PropertyConfig} from '../types'
import DupontQrCode from '../assets/qr-code-wadc0006.png'
import NomaQrCode from '../assets/qr-code-wadc0009.png'
import NavyYardQrCode from '../assets/qr-code-wadc0010.png'
import DefaultQrCode from '../assets/qr-code-default.png'
import NavyYardAccessInfo from '../components/access/NavyYardAccessInfo'
import NavyYardCheckinDirections from '../components/directions/NavyYardCheckinDirections'
import DupontCheckinDirections from '../components/directions/DupontCheckinDirections'

export const PROPERTY_ID_COOKIE = 'property-id'

export function getPropertyId(): string {
  return Cookies.get(PROPERTY_ID_COOKIE)
}

export function setPropertyId(propertyId: string): void {
  Cookies.set(PROPERTY_ID_COOKIE, propertyId, {expires: 365})
}

export function removePropertyId(): void {
  Cookies.remove(PROPERTY_ID_COOKIE)
}

const propertyConfigs: Record<string, PropertyConfig> = {
  'WADC0006': {
    name: 'Dupont Circle',
    code: 'WADC0006',
    helpModalButtonEnabled: true,
    helpPageButtonEnabled: false,
    helpHasCamera: true,
    helpQrCode: DupontQrCode,
    helpPhone: '+1 (202) 403-2238',
    paymentTerminalEnabled: false,
    CustomCheckinDirectionsComponent: DupontCheckinDirections
  },
  'WADC0009': {
    name: 'NoMa',
    code: 'WADC0009',
    helpModalButtonEnabled: true,
    helpPageButtonEnabled: true,
    helpHasCamera: true,
    helpQrCode: NomaQrCode,
    helpPhone: '+1 (202) 370-7314',
    paymentTerminalEnabled: false
  },
  'WADC0010': {
    name: 'Navy Yard',
    code: 'WADC0010',
    helpModalButtonEnabled: true,
    helpPageButtonEnabled: true,
    helpHasCamera: true,
    helpPhone: '+1 (202) 796-4424',
    helpQrCode: NavyYardQrCode,
    paymentTerminalEnabled: false,
    CustomAccessInfoComponent: NavyYardAccessInfo,
    CustomCheckinDirectionsComponent: NavyYardCheckinDirections
  },
  'TEST': {
    name: 'Test Property',
    code: 'TEST',
    helpModalButtonEnabled: true,
    helpPageButtonEnabled: false,
    helpHasCamera: true,
    helpPhone: '+1 (222) 333-4444',
    helpQrCode: DefaultQrCode,
    paymentTerminalEnabled: true
  },
  'WHYDCPENN': {
    name: 'Z Test Property',
    code: 'WHYDCPENN',
    helpModalButtonEnabled: true,
    helpPageButtonEnabled: false,
    helpHasCamera: true,
    helpPhone: '+1 (222) 333-4444',
    helpQrCode: DefaultQrCode,
    paymentTerminalEnabled: false
  }
}

export function getPropertyConfig(): PropertyConfig {
  const propertyId = getPropertyId()
  const propertyConfig = propertyConfigs[propertyId]
  if (!propertyConfig) {
    throw new Error(`Property ID ${propertyId} does not have a configuration`)
  }

  return propertyConfig
}
