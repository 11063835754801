import React from 'react'
import {ReservationCheckin} from '../../types'

export interface NavyYardCheckinDirectionsProps {
  reservationCheckin: ReservationCheckin
}

const NavyYardCheckinDirections = ({
  reservationCheckin
}: NavyYardCheckinDirectionsProps) => {
  const roomNumber = reservationCheckin?.checkinDetails?.roomNumber || ''
  const isWestBuilding = roomNumber.startsWith('W')
  return (
    <>
      <p style={{fontSize: 20}}>
        {isWestBuilding
          ? 'Your apartment is located in the West Building (816 Potomac Ave, SE), ' +
            'which is located right next to this building. As you exit the building, ' +
            'head to the right down the block.'
          : 'Your apartment is located in this building (East Building). ' +
            'Elevators can be found opposite the Guest Services area.'}
        <br />
        <br />
        We hope you have an excellent stay at Placemakr, Navy Yard. If you need
        anything, we're here for you 24/7 via text, phone, or email.
      </p>
    </>
  )
}

export default NavyYardCheckinDirections
