import React from 'react'

const DupontCheckinDirections = () => {
  return (
    <p style={{fontSize: 24}}>
      You're all set! We hope you have an excellent stay at Placemakr, Dupont
      Circle. Grab an information sheet attached to the neck of the kiosk. If
      you need anything, we're here for you 24/7 via text, phone, or email.
    </p>
  )
}

export default DupontCheckinDirections
