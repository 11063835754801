import React, {useEffect} from 'react'
import CheckinCheckmark from '../../assets/checkin-checkmark-green.png'
import StepLabel from '../common/StepLabel'
import {faCircleRight} from '@fortawesome/free-regular-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import ReactGA from 'react-ga4'
import {useLocation} from 'react-router-dom'

export interface VerifyIdentityCompleteProps {
  onNext: () => void
}

const VerifyIdentityComplete = ({onNext}: VerifyIdentityCompleteProps) => {
  const location = useLocation()
  useEffect(() => {
    ReactGA.event({
      category: 'Checkin_Kiosk',
      action: 'Guest_Verification_Complete',
      value: 1
    })
  }, [])

  return (
    <section className="w-1/2 flex flex-col items-center justify-start gap-8 mt-12 mx-auto text-center">
      <StepLabel label="2" />
      <h1 className="header-2 font-semibold">Guest Verification Complete</h1>
      <img src={CheckinCheckmark} className="h-52 my-8" />
      <button
        className="btn btn-secondary btn-wide font-bold text-xl"
        onClick={() => {
          ReactGA.event({
            category: 'Checkin_Kiosk',
            action: 'Verification_Complete',
            value: 1
          })
          onNext()
        }}
      >
        <FontAwesomeIcon icon={faCircleRight} />
        continue
      </button>
    </section>
  )
}

export default VerifyIdentityComplete
