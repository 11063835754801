import React, {useEffect} from 'react'
import BrandLogo from '../../assets/placemakr-logo-sky.png'
import {useAppDispatch, useAppSelector} from '../../store/hooks'
import {selectIsHelpOpen, setIsHelpOpen} from '../../store/helpSlice'
import HelpModal from '../help/HelpModal'
import {useNavigate} from 'react-router-dom'
import {getLogger} from '../../services/logging'
import {faQuestionCircle} from '@fortawesome/free-regular-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const logger = getLogger('OfflineErrorPage')

const OfflineErrorPage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isHelpOpen = useAppSelector(selectIsHelpOpen)

  useEffect(() => {
    const ref = setInterval(() => {
      logger.info({
        message: 'Checking if checkin kiosk is online...',
        data: {
          isOnline: window.navigator.onLine === true
        }
      })
      if (window.navigator.onLine === true) {
        logger.info({message: 'Application is online'})
        navigate('/')
      } else {
        logger.info({message: 'Application is offline'})
      }
    }, 5000)

    return () => clearInterval(ref)
  }, [])

  return (
    <>
      <section
        className={
          'w-full min-h-screen flex flex-col items-center justify-center ' +
          'bg-primary text-white text-center'
        }
      >
        <img src={BrandLogo} className="w-16 mb-8" />
        <h1 className="tracking-wide" style={{fontSize: '60px'}}>
          Guest Checkin is <span className="font-bold">Offline!</span>
        </h1>
        <p className="mb-8 mx-24 text-lg">Please contact us for help.</p>
        <button
          className="btn btn-secondary btn-wide font-bold text-xl"
          onClick={() => dispatch(setIsHelpOpen(true))}
        >
          <FontAwesomeIcon icon={faQuestionCircle} />
          <span>get help</span>
        </button>
      </section>
      <HelpModal
        isOpen={isHelpOpen}
        onClose={() => {
          dispatch(setIsHelpOpen(false))
        }}
      />
    </>
  )
}

export default OfflineErrorPage
