import React from 'react'
import {getPropertyConfig} from '../../services/propertyContext'

const DefaultCheckinDirections = () => {
  const {name} = getPropertyConfig()
  return (
    <p style={{fontSize: 24}}>
      You're all set! We hope you have an excellent stay at Placemakr, {name}.
      If you need anything, we're here for you 24/7 via text, phone, or email.
    </p>
  )
}

export default DefaultCheckinDirections
