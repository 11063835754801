import React, {useEffect} from 'react'
import CheckinError from '../../assets/checkin-error.png'
import StepLabel from '../common/StepLabel'
import {useAppDispatch} from '../../store/hooks'
import {setIsHelpOpen} from '../../store/helpSlice'
import {faQuestionCircle} from '@fortawesome/free-regular-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import ReactGA from 'react-ga4'
import {useLocation} from 'react-router-dom'

const VerifyIdentityRejected = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()

  useEffect(() => {
    ReactGA.event({
      category: 'Checkin_Kiosk',
      action: 'Guest_Verification_Rejected',
      value: 1
    })
  }, [])

  return (
    <section className="w-1/2 flex flex-col items-center justify-start gap-8 mt-12 mx-auto text-center">
      <StepLabel label="2" />
      <h1 className="header-2 font-semibold">Guest Verification Failed</h1>
      <img src={CheckinError} className="h-52" />
      <p className="text-lg text-center">
        We were unable verify your identity.
        <br />
        Please contact support for help.
      </p>
      <button
        className="btn btn-secondary btn-wide font-bold text-xl"
        onClick={() => dispatch(setIsHelpOpen(true))}
      >
        <FontAwesomeIcon icon={faQuestionCircle} />
        <span>get help</span>
      </button>
    </section>
  )
}

export default VerifyIdentityRejected
