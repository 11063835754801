export enum CheckinStep {
  FindReservation = 'FindReservation',
  VerifyIdentity = 'VerifyIdentity',
  VerifyPayment = 'VerifyPayment',
  ViewRoomDetails = 'ViewRoomDetails',
  CompleteCheckin = 'CompleteCheckin'
}

export enum GuestCredentialsType {
  ConfirmationCode = 'ConfirmationCode',
  ReservationLookup = 'ReservationLookup'
}

export interface ReservationLookupCredentials {
  type: GuestCredentialsType
  firstName: string
  lastName: string
  phoneLast4: string
  checkinDate: string
  checkoutDate: string
}

export interface ConfirmationCodeCredentials {
  type: GuestCredentialsType
  confirmationCode: string
  lastName: string
}

export type GuestCredentials =
  | ReservationLookupCredentials
  | ConfirmationCodeCredentials

export interface GuestUser {
  email: string
  role: string
  propertyId: string
  bookingId: string
  reservationId: string
}

export enum AutohostVerificationStatus {
  Pending = 'pending',
  Review = 'review',
  Verified = 'verified',
  Rejected = 'rejected'
}

export enum CheckinErrorCode {
  CheckinNotFound = 'CheckinNotFound',
  CheckinDateInvalid = 'CheckinDateInvalid',
  CheckinPropertyInvalid = 'CheckinPropertyInvalid',
  IdentityVerificationInvalid = 'IdentityVerificationInvalid',
  IdentityVerificationDeclined = 'IdentityVerificationDeclined',
  PinNotAssigned = 'PinNotAssigned',
  ReservationNotFound = 'ReservationNotFound',
  ReservationCheckedIn = 'ReservationCheckedIn',
  ReservationCheckedOut = 'ReservationCheckedOut',
  ReservationCanceled = 'ReservationCanceled',
  ReservationNoShow = 'ReservationNoShow',
  ReservationInvalidStatus = 'ReservationInvalidStatus',
  UnitNotAssigned = 'UnitNotAssigned'
}

export interface CheckinAccess {
  action: CheckinAction
  code?: string
}

export enum CheckinAction {
  Allow = 'Allow',
  Deny = 'Deny'
}

export interface CheckinDetails {
  autohostReservationId: string
  autohostVerificationStatus: AutohostVerificationStatus
  autohostVerificationUrl: string
  checkinStatus: string
  roomNumber: string
  guestPhone: string
}

export interface CheckinInfo {
  checkinDate: string
  checkoutDate: string
  autohostVerificationStatus: AutohostVerificationStatus
  autohostVerificationUrl: any
  checkinStatus: string
  isPinAccess?: boolean
}

export enum LogLevel {
  DEBUG = 'DEBUG',
  INFO = 'INFO',
  WARN = 'WARN',
  ERROR = 'ERROR'
}

export interface Reservation {
  id: string
  bookingId: string
  status: string
  paymentAccount?: {
    accountNumber: string
    accountHolder: string
    expiryMonth?: string
    expiryYear?: string
    paymentMethod: string
    payerEmail: string
    isActive: boolean
  }
  property: {
    id: string
    code: string
    name: string
    description: string
  }
  ratePlan: {
    id: string
    code: string
    name: string
    description: string
    isSubjectToCityTax: boolean
  }
  unitGroup: {
    id: string
    code: string
    name: string
    description: string
    type: string
  }
  totalGrossAmount: {
    amount: number
    currency: string
  }
  arrival: string
  departure: string
  created: string
  modified: string
  adults: number
  channelCode: string
  primaryGuest: {
    firstName: string
    lastName: string
    email: string
  }
  guaranteeType: string
  cancellationFee: {
    id: string
    code: string
    name: string
    description: string
    dueDateTime: string
    fee: {
      amount: number
      currency: string
    }
  }
  balance: {
    amount: number
    currency: string
  }
  allFoliosHaveInvoice: boolean
  hasCityTax: boolean
}

export interface ReservationCheckinParams {
  kioskPropertyId: string
  propertyId: string
  reservationId: string
}

export interface ReservationContext {
  propertyId: string
  bookingId: string
  reservationId: string
  isConfirmed: boolean
}

export interface ReservationCheckin {
  reservation: Reservation
  checkinDetails: CheckinDetails
  checkinAccess: CheckinAccess
}

export interface User {
  email: string
  role: string
}

export interface PropertyConfig {
  name: string
  code: string
  helpModalButtonEnabled: boolean
  helpPageButtonEnabled: boolean
  helpHasCamera: boolean
  helpPhone: string
  helpQrCode: any
  paymentTerminalEnabled: boolean
  CustomAccessInfoComponent?: React.FC<any>
  CustomCheckinDirectionsComponent?: React.FC<any>
}

export enum NotificationType {
  VerificationReady = 'VerificationReady',
  LoginLookupFailed = 'LoginLookupFailed',
  LoginCodeFailed = 'LoginCodeFailed',
  CheckinNotAllowed = 'CheckinNotAllowed',
  CheckinError = 'CheckinError',
  CheckinStarted = 'CheckinStarted',
  CheckinCompleted = 'CheckinCompleted'
}

export interface Notification {
  type: NotificationType
}

export interface LoginLookupFailureNotification extends Notification {
  propertyId: string
  firstName: string
  lastName: string
  phoneLast4: string
  checkinDate: string
  checkoutDate: string
}

export interface LoginCodeFailureNotification extends Notification {
  propertyId: string
  confirmationCode: string
  lastName: string
}

export interface GuestVerificationNotification extends Notification {
  autohostReservationId: string
}

export interface CheckinErrorNotification extends Notification {
  error: string
}

export interface FindReservationParams {
  propertyId: string
  firstName: string
  lastName: string
  phoneLast4: string
  checkinDate: string
  checkoutDate: string
}

export interface FindReservationResult {
  confirmationCode: string
  firstName: string
  lastName: string
  email: string
  phoneLast4: string
  propertyId: string
  bookingId: string
  reservationId: string
  checkinDate: string
  checkoutDate: string
  unitType: string
}
