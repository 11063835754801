import React from 'react'
import CheckinRingCam from '../../assets/checkin-ringcam.png'
import {getPropertyConfig} from '../../services/propertyContext'
import clsx from 'clsx'

const HelpInfo = () => {
  const {helpHasCamera, helpQrCode, helpPhone} = getPropertyConfig()

  return (
    <>
      <h1 className="header-2">Let's get you some help.</h1>

      <div
        className={clsx('flex flex-row w-full px-16 py-2', {
          'justify-center': !helpHasCamera
        })}
      >
        <div className="flex flex-col items-center justify-start text-center w-2/5">
          <h3 className="uppercase text-xl font-semibold text-cabana">
            Have a personal device?
          </h3>
          <h3 className="header-2">Scan to call us on your mobile device.</h3>
          <img src={helpQrCode} className="h-36 my-8" />
          <div className="mt-4 text-lg">
            Scan QR Code to dial or call us at
            <br />
            <span className="font-bold">{helpPhone}</span>
          </div>
        </div>

        {helpHasCamera ? (
          <>
            <div className="divider divider-horizontal mx-0 w-1/5">OR</div>

            <div className="flex flex-col items-center justify-start text-center w-2/5">
              <h3 className="uppercase text-xl font-semibold text-cabana">
                No device?
              </h3>
              <h3 className="header-2">Press the button to connect with us.</h3>
              <img src={CheckinRingCam} className="h-36 my-8" />
              <div className="mt-4 text-lg">
                Device located on the wall behind the iPad.
              </div>
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    </>
  )
}

export default HelpInfo
