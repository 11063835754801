import React, {useEffect, useState} from 'react'
import {useGetVerificationStatusQuery} from '../../services/guestCheckinApi'
import {getLogger} from '../../services/logging'
import {Metric, pushMetric} from '../../services/metrics'
import {getPropertyId} from '../../services/propertyContext'
import VerifyIdentityWelcome from './VerifyIdentityWelcome'
import VerifyIdentityForm from './VerifyIdentityForm'
import VerifyIdentityStatus from './VerifyIdentityStatus'
import VerifyIdentityComplete from './VerifyIdentityComplete'
import VerifyIdentityRejected from './VerifyIdentityRejected'
import LoadingSpinner, {LoadingSpinnerSize} from '../common/LoadingSpinner'
import {AutohostVerificationStatus, ReservationCheckin} from '../../types'

interface VerifyIdentityProps {
  reservationCheckin: ReservationCheckin
  onNext: Function
}

enum ViewState {
  Loading = 'Loading',
  Welcome = 'Welcome',
  Form = 'Form',
  StatusLoading = 'StatusLoading',
  Complete = 'Complete',
  Rejected = 'Rejected'
}

const logger = getLogger('VerifyIdentity')

const VerifyIdentity = ({onNext, reservationCheckin}: VerifyIdentityProps) => {
  const [viewState, setViewState] = useState<ViewState>(ViewState.Loading)
  const [isInitialized, setIsInitialized] = useState<boolean>(false)
  const autohostReservationId =
    reservationCheckin?.checkinDetails?.autohostReservationId
  const autohostVerificationUrl =
    reservationCheckin?.checkinDetails?.autohostVerificationUrl
  const verificationStatusQuery = useGetVerificationStatusQuery(null)

  useEffect(() => {
    // determine view status based on verification status
    if (!isInitialized) {
      if (verificationStatusQuery.isSuccess) {
        setIsInitialized(true)
        const verificationStatus =
          verificationStatusQuery.data?.autohostVerificationStatus

        if (verificationStatus === AutohostVerificationStatus.Verified) {
          setViewState(ViewState.Complete)
        } else if (verificationStatus === AutohostVerificationStatus.Review) {
          setViewState(ViewState.StatusLoading)
        } else {
          setViewState(ViewState.Welcome)
        }
      } else if (verificationStatusQuery.isError) {
        logger.error({
          message: 'Initial verification status query failed.',
          data: {verificationStatusQuery}
        })
        setIsInitialized(true)
        setViewState(ViewState.Welcome)
      }
    }
  }, [verificationStatusQuery])

  logger.debug({
    message: 'View state changed',
    data: {
      viewState
    }
  })

  if (viewState === ViewState.Loading) {
    return (
      <div className="h-full flex justify-center items-center gap-2">
        <LoadingSpinner size={LoadingSpinnerSize.Large} />
        <div>Loading...</div>
      </div>
    )
  } else if (viewState === ViewState.Welcome) {
    return <VerifyIdentityWelcome onNext={() => setViewState(ViewState.Form)} />
  } else if (viewState === ViewState.Form) {
    return (
      <VerifyIdentityForm
        verificationUrl={autohostVerificationUrl}
        onNext={() => {
          setViewState(ViewState.StatusLoading)
        }}
      />
    )
  } else if (viewState === ViewState.StatusLoading) {
    return (
      <VerifyIdentityStatus
        autohostReservationId={autohostReservationId}
        onComplete={isVerified => {
          if (isVerified) {
            setViewState(ViewState.Complete)
          } else {
            setViewState(ViewState.Rejected)
          }
        }}
      />
    )
  } else if (viewState === ViewState.Complete) {
    return (
      <VerifyIdentityComplete
        onNext={() => {
          pushMetric(Metric.IdentityVerificationCompleted, 1, {
            Property: getPropertyId()
          })
          onNext()
        }}
      />
    )
  } else if (viewState === ViewState.Rejected) {
    return <VerifyIdentityRejected />
  } else {
    return <></>
  }
}

export default VerifyIdentity
