import React from 'react'
import HelpInfo from '../help/HelpInfo'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircleLeft} from '@fortawesome/free-regular-svg-icons'
import {useNavigate} from 'react-router-dom'

const HelpPage = () => {
  const navigate = useNavigate()
  return (
    <>
      <div className="w-full static bg-yellow-100 py-1">
        <h3 className="text-center header-3">Placemakr Support</h3>
      </div>
      <div className="grow flex flex-col items-center justify-center gap-12 mt-4">
        <HelpInfo />
        <button
          className="btn btn-secondary btn-wide mt-4 mb-8"
          onClick={() => navigate('/')}
        >
          <FontAwesomeIcon icon={faCircleLeft} />
          <span>back</span>
        </button>
      </div>
    </>
  )
}

export default HelpPage
