import React, {useEffect, useRef} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faXmark} from '@fortawesome/free-solid-svg-icons'
import {faXmarkCircle} from '@fortawesome/free-regular-svg-icons'
import HelpInfo from './HelpInfo'

interface HelpModalProps {
  isOpen: boolean
  onClose: () => void
}

const HelpModal = ({isOpen, onClose}: HelpModalProps) => {
  const modalElement = useRef<any>(null)

  // modal open/closed
  useEffect(() => {
    if (modalElement.current) {
      const modal = modalElement.current
      if (isOpen) {
        modal.showModal()
      } else {
        modal.close()
      }
    }
  }, [isOpen])

  return (
    <dialog ref={modalElement} className="modal">
      <div className="help-modal-box flex flex-col">
        <div className="grow-0 flex flex-col justify-start items-center">
          <div className="w-full static bg-yellow-100 py-1">
            <h3 className="text-center header-3">Placemakr Support</h3>
            <div className="absolute top-1 right-4">
              <button
                className="btn btn-ghost btn-circle border-0 focus:outline-none "
                onClick={() => {
                  onClose()
                }}
              >
                <FontAwesomeIcon icon={faXmark} className="text-xl" />
              </button>
            </div>
          </div>
        </div>

        <div className="grow flex flex-col items-center justify-center gap-12 mt-4">
          <HelpInfo />
          <button
            className="btn btn-secondary btn-wide mt-4 mb-8"
            onClick={() => onClose()}
          >
            <FontAwesomeIcon icon={faXmarkCircle} />
            <span>close</span>
          </button>
        </div>
      </div>
    </dialog>
  )
}

export default HelpModal
