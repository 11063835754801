import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {getConfig} from './config'
import {getGuestToken} from './guestToken'
import {getLogger} from './logging'
import {Notification, GuestCredentials, FindReservationParams} from '../types'
import {getPropertyId} from './propertyContext'

const {guestCheckinApiUrl} = getConfig()
const logger = getLogger('guestCheckinApi')

export const guestCheckinApi = createApi({
  reducerPath: 'guestCheckinApi',
  baseQuery: fetchBaseQuery({
    baseUrl: guestCheckinApiUrl,
    prepareHeaders: async (headers): Promise<Headers> => {
      try {
        // injects authorization token into request headers
        const token = getGuestToken()
        if (token?.length) {
          headers.set('Authorization', `Bearer ${token}`)
        }
      } catch (e) {
        logger.error({
          message: 'Error occurred setting guest token on HTTP request',
          errorData: {
            error: e
          }
        })
      }
      return headers
    }
  }),
  tagTypes: [],
  endpoints: builder => ({
    checkinGuest: builder.mutation({
      query() {
        return {
          url: '/checkin',
          method: 'PUT'
        }
      },
      invalidatesTags: []
    }),
    findReservation: builder.query({
      query(params: FindReservationParams) {
        return {
          url: '/find-reservation',
          method: 'POST',
          body: params
        }
      },
      providesTags: []
    }),
    getPaymentMethods: builder.query({
      query() {
        return `/payment-methods`
      },
      providesTags: []
    }),
    getPaymentSession: builder.query({
      query() {
        return {
          url: `/payment-session`,
          method: 'POST',
          body: {
            'returnUrl': window.location.href,
            'countryCode': 'US'
          }
        }
      },
      providesTags: []
    }),
    getPaymentStatus: builder.query({
      query(paymentSessionId: string) {
        return `/payment-status?paymentSessionId=${paymentSessionId}`
      },
      providesTags: []
    }),
    getProperty: builder.query({
      query(propertyId: string) {
        return `/property?propertyId=${propertyId}`
      },
      providesTags: []
    }),
    getReservationCheckin: builder.query({
      query(kioskPropertyId: string) {
        return `/reservation-checkin` + `?kioskPropertyId=${kioskPropertyId}`
      },
      providesTags: []
    }),
    getVerificationStatus: builder.query({
      query() {
        return `/verification-status`
      },
      providesTags: []
    }),
    login: builder.mutation({
      query(credentials: GuestCredentials) {
        return {
          url: `/authenticate?kioskPropertyId=${getPropertyId()}`,
          method: 'POST',
          body: credentials
        }
      },
      invalidatesTags: []
    }),
    sendCheckinNotification: builder.mutation({
      query(notification: Notification) {
        return {
          url: '/checkin-notification',
          method: 'POST',
          body: notification
        }
      },
      invalidatesTags: []
    }),
    sendLoginNotification: builder.mutation({
      query(notification: Notification) {
        return {
          url: '/login-notification',
          method: 'POST',
          body: notification
        }
      },
      invalidatesTags: []
    }),
    validateToken: builder.mutation({
      query() {
        return {
          url: '/introspect',
          method: 'GET'
        }
      },
      invalidatesTags: []
    })
  })
})

export const {
  useCheckinGuestMutation,
  useFindReservationQuery,
  useGetPaymentMethodsQuery,
  useGetPaymentSessionQuery,
  useGetPaymentStatusQuery,
  useGetPropertyQuery,
  useGetReservationCheckinQuery,
  useGetVerificationStatusQuery,
  useLoginMutation,
  useSendCheckinNotificationMutation,
  useSendLoginNotificationMutation,
  useValidateTokenMutation
} = guestCheckinApi
