import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from './store'
import {ReservationContext} from '../types'

// slice
export const reservationSlice = createSlice({
  name: 'reservation',
  initialState: {},
  reducers: {
    setReservation: (state, action: PayloadAction<ReservationContext>) => {
      state = action.payload
      return state
    }
  }
})

// selectors
export const selectReservationContext = (
  state: RootState
): ReservationContext => {
  return state.reservation
}

// actions
export const {setReservation} = reservationSlice.actions

// reducer
export default reservationSlice.reducer
