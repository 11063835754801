import React, {useEffect, useState} from 'react'
import ViewAccessInfoModal from './ViewAccessInfoModal'
import StepLabel from '../common/StepLabel'
import clsx from 'clsx'
import {
  useCheckinGuestMutation,
  useSendCheckinNotificationMutation
} from '../../services/guestCheckinApi'
import {useAppDispatch} from '../../store/hooks'
import {setIsHelpOpen} from '../../store/helpSlice'
import CheckinError from '../../assets/checkin-error.png'
import LoadingSpinner, {LoadingSpinnerSize} from '../common/LoadingSpinner'
import CheckinDetails from '../../assets/checkin-details.png'
import CheckinPhone from '../../assets/checkin-phone.png'
import {getLogger} from '../../services/logging'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMobileScreen} from '@fortawesome/free-solid-svg-icons'
import {
  faCircleRight,
  faQuestionCircle
} from '@fortawesome/free-regular-svg-icons'
import ReactGA from 'react-ga4'
import {CheckinErrorNotification, NotificationType} from '../../types'

interface ViewAccessInfoProps {
  onNext: Function
}

enum ViewState {
  Info = 'Info',
  Complete = 'Complete',
  Error = 'Error'
}

const logger = getLogger('ViewAccessInfo')

const ViewAccessInfo = ({onNext}: ViewAccessInfoProps) => {
  const dispatch = useAppDispatch()
  const [viewState, setViewState] = useState<ViewState>(ViewState.Info)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isNoPhoneMode, setIsNoPhoneMode] = useState<boolean>(false)
  const [checkinGuest, checkinGuestMutation] = useCheckinGuestMutation()
  const [sendCheckNotification] = useSendCheckinNotificationMutation()

  useEffect(() => {
    ReactGA.event({
      category: 'Checkin_Kiosk',
      action: 'Room_Details',
      value: 1
    })
  }, [])

  logger.debug({
    message: 'View state changed',
    data: {
      viewState
    }
  })

  if (viewState === ViewState.Info) {
    return (
      <>
        <section className="flex flex-col items-center justify-start gap-4 mt-12">
          <StepLabel label="3" />
          <h1 className="header-2 font-bold">Room Details</h1>
          <img src={CheckinDetails} className="h-52" />
          <div className="text-xl text-center w-1/2 mb-4">
            <span className="font-bold">
              The most secure way to share you room details is via text.{' '}
            </span>
            Click the button below to have your details sent to the mobile phone
            number that corresponds with your Placemakr reservation.
          </div>

          <div className="flex flex-col items-center gap-2">
            <button
              className={clsx('btn btn-secondary w-96 mb-4', {
                hidden: isNoPhoneMode
              })}
              disabled={checkinGuestMutation.isLoading}
              onClick={() => {
                ReactGA.event({
                  category: 'Checkin_Kiosk',
                  action: 'Checkin_Details_Sms_Send',
                  value: 1
                })

                checkinGuest(null)
                  .unwrap()
                  .then(() => {
                    setViewState(ViewState.Complete)
                  })
                  .catch(() => {
                    // send checkin not allowed notification
                    sendCheckNotification({
                      type: NotificationType.CheckinNotAllowed,
                      error: 'Guest checkin process failed.'
                    } as CheckinErrorNotification)

                    setViewState(ViewState.Error)
                  })
              }}
            >
              <span className={clsx({hidden: !checkinGuestMutation.isLoading})}>
                <LoadingSpinner size={LoadingSpinnerSize.Small} />
              </span>
              <FontAwesomeIcon icon={faMobileScreen} />
              <span>
                {!checkinGuestMutation.isLoading
                  ? 'text me my information'
                  : 'sending information'}
              </span>
            </button>

            <a
              className="link link-primary"
              onClick={() => {
                setIsNoPhoneMode(true)
                setIsModalOpen(true)

                ReactGA.event({
                  category: 'Checkin_Kiosk',
                  action: 'Checkin_Details_View',
                  value: 1
                })
              }}
            >
              I don't have a phone
            </a>
          </div>
        </section>

        <ViewAccessInfoModal
          isOpen={isModalOpen}
          onClose={({hasErrors, hasBeenViewed}) => {
            setIsModalOpen(false)
            if (hasErrors) {
              setViewState(ViewState.Error)
            } else if (hasBeenViewed) {
              setViewState(ViewState.Complete)
            }
          }}
        />
      </>
    )
  }

  if (viewState === ViewState.Complete) {
    return (
      <section className="flex flex-col items-center justify-start gap-4 mt-12">
        <StepLabel label="3" />
        <h1 className="header-2 font-semibold">Room Details Complete</h1>
        <h2 className="text-center w-1/2 my-2" style={{fontSize: 24}}>
          A text message containing room access information was sent to the
          phone number on the reservation.
        </h2>
        <img src={CheckinPhone} className="h-52 my-8 mx-auto" />
        <button
          className="btn btn-secondary btn-wide font-bold text-xl"
          onClick={() => onNext()}
        >
          <FontAwesomeIcon icon={faCircleRight} />
          <span>continue</span>
        </button>
      </section>
    )
  }

  if (viewState === ViewState.Error) {
    return (
      <section className="flex flex-col items-center justify-start gap-8 mt-12">
        <StepLabel label="3" />
        <h1 className="header-2 font-semibold">Checkin Error</h1>
        <p className="text-lg text-center">
          We were unable to retrieve your room details.
          <br />
          Please contact support for help.
        </p>
        <img src={CheckinError} className="h-52 my-8 mx-auto" />
        <button
          className="btn btn-secondary btn-wide font-bold text-xl"
          onClick={() => dispatch(setIsHelpOpen(true))}
        >
          <FontAwesomeIcon icon={faQuestionCircle} />
          <span>get help</span>
        </button>
      </section>
    )
  }
}

export default ViewAccessInfo
