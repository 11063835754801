import React, {useEffect} from 'react'
import WelcomePage from './pages/WelcomePage'
import {
  NavigateFunction,
  Route,
  Routes,
  useLocation,
  useNavigate
} from 'react-router-dom'
import CheckinPage from './pages/CheckinPage'
import {useAppDispatch, useAppSelector} from '../store/hooks'
import {selectIsInitialized, setAuthenticated} from '../store/authSlice'
import LoadingSpinner, {LoadingSpinnerSize} from './common/LoadingSpinner'
import {getGuestToken, getGuestUser} from '../services/guestToken'
import {useValidateTokenMutation} from '../services/guestCheckinApi'
import {setUser} from '../store/userSlice'
import {
  getPropertyConfig,
  getPropertyId,
  setPropertyId
} from '../services/propertyContext'
import {setReservation} from '../store/reservationSlice'
import CheckinErrorPage from './pages/CheckinErrorPage'
import {AnyAction, Dispatch} from '@reduxjs/toolkit'
import OfflineErrorPage from './pages/OfflineErrorPage'
import {getConfig} from '../services/config'
import {getLogger} from '../services/logging'
import {Metric, pushMetric} from '../services/metrics'
import ReactGA from 'react-ga4'
import HelpPage from './pages/HelpPage'

const logger = getLogger('App')
const {googleAnalyticsId, heartbeatEnabled, heartbeatInterval} = getConfig()

function initPropertyContext(
  dispatch: Dispatch<AnyAction>,
  propertyIdParam: string
): void {
  const propertyIdCookie = getPropertyId()
  const propertyId = propertyIdParam || propertyIdCookie

  if (propertyId) {
    logger.info({
      message: `Initializing propertyId for guest checkin kiosk: ${propertyId}`
    })
    setPropertyId(propertyId)
  } else {
    logger.error({message: `propertyId not set for guest checkin kiosk`})
    throw Error('Property ID must be set for guest checkin kiosk.')
  }
}

function initUserContext(
  dispatch: Dispatch<AnyAction>,
  validateToken: Function
): void {
  // read guest auth token and determine authentication status
  const token = getGuestToken()

  logger.info({message: 'Initializing user context...', data: {token}})

  if (!token) {
    logger.info({message: 'No token found'})
    dispatch(setAuthenticated(false))
  } else {
    logger.info({message: 'Validating token', data: {token}})
    validateToken(token)
      .unwrap()
      .then(() => {
        // set authentication status and user
        logger.info({message: 'Token is valid', data: {token}})

        const {
          email,
          role,
          propertyId,
          bookingId,
          reservationId
        } = getGuestUser(token)

        // set user
        dispatch(
          setUser({
            email,
            role
          })
        )

        // set reservation context
        dispatch(
          setReservation({
            propertyId,
            bookingId,
            reservationId,
            isConfirmed: false
          })
        )

        // set authenticated flag
        dispatch(setAuthenticated(true))
      })
      .catch(() => {
        // set authentication status, nullify token/user, redirect to login
        logger.info({message: 'Token is invalid', data: {token}})
        dispatch(setAuthenticated(false))
        dispatch(setUser(null))
      })
  }
}

function initConnectivityListener(navigate: NavigateFunction): void {
  logger.info({message: `Initializing connectivity listener`})

  window.addEventListener('offline', () => {
    navigate('/offline')
  })

  if (window.navigator.onLine !== true) {
    navigate('/offline')
  }
}

function initHeartbeat(propertyId: string): void {
  logger.info({
    message: `Initializing heartbeat...`,
    data: {propertyId, heartbeatEnabled, heartbeatInterval}
  })
  if (heartbeatEnabled && propertyId) {
    setInterval(() => {
      logger.info({
        message: `Heartbeat for guest checkin kiosk`,
        data: {propertyId}
      })
      pushMetric(Metric.Heartbeat, 1, {Property: propertyId})
    }, heartbeatInterval)
  }
}

/**
 * Initializes Google Analytics.
 * @param googleAnalyticsId - The Google Analytics tracking ID.
 */
function initGoogleAnalytics(googleAnalyticsId: string): void {
  logger.info({
    message: `Initializing Google Analytics...`,
    data: {googleAnalyticsId}
  })
  ReactGA.initialize(googleAnalyticsId)
}

const App = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [validateToken] = useValidateTokenMutation()
  const isInitialized = useAppSelector(selectIsInitialized)

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const propertyId = searchParams.get('propertyId')
    const config = getConfig()

    logger.info({
      message: 'Initializing checkin kiosk...',
      data: {propertyId, config}
    })

    if (!isInitialized) {
      initPropertyContext(dispatch, propertyId)
      initUserContext(dispatch, validateToken)
      initConnectivityListener(navigate)
      initHeartbeat(propertyId)
      initGoogleAnalytics(googleAnalyticsId)
    }
  }, [])

  useEffect(() => {
    if (isInitialized) {
      logger.info({
        message: `Sending pageview to Google Analytics: ${location.pathname}`
      })
    }
  }, [location?.pathname, isInitialized])

  if (isInitialized) {
    return (
      <main>
        <Routes>
          <Route index element={<WelcomePage />} />
          <Route path="checkin" element={<CheckinPage />} />
          <Route path="help" element={<HelpPage />} />
          <Route path="error" element={<CheckinErrorPage />} />
          <Route path="offline" element={<OfflineErrorPage />} />
        </Routes>
      </main>
    )
  } else {
    return (
      <main>
        <div className="min-h-screen flex flex-row justify-center items-center gap-2">
          <LoadingSpinner size={LoadingSpinnerSize.Large} />
          <div>Loading...</div>
        </div>
      </main>
    )
  }
}

export default App
