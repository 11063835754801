import React, {useEffect} from 'react'
import StepLabel from '../common/StepLabel'
import {getLogger} from '../../services/logging'
import ReactGA from 'react-ga4'

interface VerifyIdentityFormProps {
  verificationUrl: string
  onNext: () => void
}

const logger = getLogger('VerifyIdentityForm')

const VerifyIdentityForm = ({
  verificationUrl,
  onNext
}: VerifyIdentityFormProps) => {
  logger.info({
    message: 'Loading Autohost guest portal iframe',
    data: {
      verificationUrl
    }
  })

  useEffect(() => {
    ReactGA.event({
      category: 'Checkin_Kiosk',
      action: 'Guest_Verification_Form',
      value: 1
    })
  }, [])

  useEffect(() => {
    if (!verificationUrl) {
      return
    }

    logger.info({
      message: 'Initializing message listener for Autohost guest portal iframe',
      data: {verificationUrl}
    })

    const verificationOrigin = new URL(verificationUrl).origin

    // Process guest portal events from iframe - see autohost docs:
    // https://docs.autohost.ai/docs/knowledge/integrations/embedding-the-guest-portal-using-iframe

    window.onmessage = (message: MessageEvent) => {
      try {
        logger.info({
          message: 'Received message',
          data: {message}
        })

        if (message.origin === verificationOrigin) {
          const event = JSON.parse(message.data)

          if (event.type === 'guestportal-completed') {
            logger.info({
              message: 'Autohost guest portal completed',
              data: {event}
            })

            ReactGA.event({
              category: 'Checkin_Kiosk',
              action: 'Verification_Form_Complete',
              value: 1
            })

            // advance to next step
            onNext()
          } else if (event.type === 'guestportal-step-completed') {
            logger.info({
              message: 'Autohost guest portal step completed',
              data: {event}
            })

            ReactGA.event({
              category: 'Checkin_Kiosk',
              action: `Guest_Verification_Step_Complete->${event.step}`,
              value: 1
            })
          } else {
            logger.warn({
              message: `Autohost guest portal - unhandled event type: ${event.type}`,
              data: {event}
            })
          }
        }
      } catch (e) {
        logger.error({
          message: 'Autohost iframe message handler failed to process message',
          errorData: {error: e}
        })
      }
    }
  }, [verificationUrl])

  return (
    <section className="px-16 text-center mx-auto mt-12">
      <StepLabel label="2" />

      <h1 className="header-2 font-semibold">Guest Verification</h1>

      {verificationUrl ? (
        <iframe
          src={`${verificationUrl}?embed=1&kiosk=1&hide=logo,topbar,image`}
          allow="geolocation; camera; fullscreen;"
          width="100%"
          height="600"
          className="border border-solid my-4 py-4"
        ></iframe>
      ) : (
        ''
      )}
    </section>
  )
}

export default VerifyIdentityForm
