import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from './store'

export interface AuthState {
  authenticated: boolean
  initialized: boolean
}

// slice
export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    authenticated: false,
    initialized: false
  },
  reducers: {
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      state = {
        initialized: true,
        authenticated: action.payload
      }
      return state
    }
  }
})

// selectors
export const selectIsAuthenticated = (state: RootState) => {
  return state.auth?.authenticated === true
}

export const selectIsInitialized = (state: RootState) => {
  return state.auth?.initialized === true
}

// actions
export const {setAuthenticated} = authSlice.actions

// reducer
export default authSlice.reducer
